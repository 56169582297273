.SidebarBackground{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.1);
}

.Sidebar{
  position: fixed;
  top: 8px;
  right: 8px;
  width: 360px;
  max-width: calc(100% - 16px);
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 16px 32px -8px rgba(0,0,0,.24);
  overflow:hidden;
  background: #fff;
  animation: slide-in-from-left .4s forwards ease;
}

.SidebarClose{
  position: absolute;
  top: 12px;
  right: 12px;
  height: 20px;
  opacity: .8;
  cursor: pointer;
  z-index: 12;
  border-radius: 50%;
  background: #fff;
  padding: 4px;
}

@keyframes slide-in-from-left{
  from{
    top: -100%;
    opacity: 0;
  }
  to{
    top: 8px;
    opacity: 1;
  }
}


#SidebarExit{
  animation: slide-out-to-left .5s forwards ease;
}

@keyframes slide-out-to-left {
  from{
    top: 8px;
  }
  to{
    top: -100%;
  }
}

.SidebarProfile{
  background: #eee;
}

.SidebarProfileImg{
  width: 42px;
  padding: 12px;
  display: inline-block;
  vertical-align: middle;
}

.SidebarProfileName{
  font-size: 24px;
  font-weight: 900;
  color: #222;
  display: inline-block;
  vertical-align: middle;
  padding: 16px 4px;
}

.Sidebar li {
  font-size: 24px;
  padding: 8px 24px;
  font-weight: 500;
  color: #666;
}

.SidebarVersion{
  padding: 8px 24px;
  font-size: 12px;
}