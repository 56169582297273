input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}
.quantity-input {
  width: 36px;
  height: 36px;
  padding: 2px;
  box-shadow: rgb(222, 222, 222) 0px 2px 3px 1px inset;
  background-color: whitesmoke;
  border: 0px;
  border-radius: 5px;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  border: 1px solid lightgray;
}
.exceed {
  border: 1px solid red;
}
