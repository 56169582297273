.checkerPageContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
}

.restaurantInfoContainer {
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: flex-start;
}

.restaurantLogo {
  width: 120px;
}

.restaurauntTextContainer {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin-left: 12px;
}

.restaurantName {
  font-weight: 900;
  font-size: 24px;
  line-height: 1.1;
  margin-bottom: 4px;
}

/* .restaurantAddress{
  font-weight: 300;
  font-size: 16px;
  line-height: 1.2;
} */

@media only screen and (max-width: 350px) {
  .restaurantLogo {
    width: 96px;
  }
  .restaurantName {
    font-size: 20px;
  }
  .restaurantAddress {
    font-size: 14px;
  }
}

.hours {
  font-size: 18px;
  font-weight: 700;
  margin-top: 16px;
}

.deliveryButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.checker__action-button {
  border-radius: 4px;
  position: relative;
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition: box-shadow 100ms linear;
  display: flex;
  flex-direction: column;
  flex-basis: 48%;
}

.checker__cta {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 4px;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 1rem;
}

.checker__cta:not(.hngrButton-grey) > svg {
  transition: transform 50ms linear;
}

.checker__cta:not(.hngrButton-grey):hover > svg {
  transform: translateX(2px);
}

.secondary-bg {
  background-color: var(--rest-secondary);
}

.checker__action-button:active {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.checker__action-button:not(.checker__action-button--selected) {
  color: #a8a8a8;
}

.checker__action-button--selected {
  border-color: var(--rest-primary);
  border-width: 2px;
  border-style: solid;
  box-shadow: none;
}

.checker__action-button--deactivated {
  border: none;
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: none;
  background-color: #e8e8e8;
  color: #a8a8a8;
}

.checker__action-button--selected > .checker__action-button-circle {
  border: 1px solid var(--rest-primary);
  background: var(--rest-primary) content-box;
  padding: 0.1rem;
  /* animation: grow-in 250ms cubic-bezier(0.16, 1, 0.3, 1); */
}

.checker__action-button-circle {
  width: 16px;
  height: 16px;
  transition: background 150ms linear;
  border: 1px solid #a8a8a8;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.65rem;
}

.checker__icon {
  /* margin-bottom: 0.5rem; */
}

.checker__icon > path {
  fill: #a8a8a8;
}

.checker__icon--selected > path {
  fill: var(--rest-primary);
}

.checker__icon--deactivated > path {
  fill: #a8a8a8;
}

.deliveryButton {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  border: 0;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.buttonBlue {
  background: #6badf4;
  color: #fff;
  font-weight: 900;
}

.buttonWhite {
  background: #fff;
  color: #999;
  font-weight: 500;
}

.addressWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;

  z-index: 3;
  display: flex;
  align-items: center;
}

.addressWrapper--animate {
  animation: drop-location cubic-bezier(0.16, 1, 0.3, 1) 400ms;
}

.addressWrapper--error .autocompleteWrapper {
  border-color: #ea5151;
}

.addressWrapper--error .autocompleteWrapper > .location-search-container,
.addressWrapper--error .autocompleteWrapper input {
  background: linear-gradient(0deg, rgba(255, 0, 0, 0.1), rgba(255, 0, 0, 0.1)),
    #ffffff;
}

.address-input-container {
  width: 100%;
  position: relative;
}

.checkerAddressWrapper {
  flex-direction: column;
  align-items: flex-start;
}

@keyframes grow-in {
  0% {
    transform: scale3d(0.1, 0.1, 0.1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes drop-location {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.spin > svg {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.autocompleteWrapper {
  display: block;
  border-radius: 6px;
  border: 1px solid #a8a8a8;
}

.autocompleteContainer {
  text-align: center;
  border: 0px;
  position: relative;
  width: 100%;
}

.autocompleteError {
  color: #ea5151;
  margin: 0.5rem 0 0 0;
}

.buttonPrimary {
  position: relative;
  color: white;
  border-radius: 40px;
  border: 0;
  padding: 14px 30px;
  width: calc(100% - 20px);
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 2px;
  margin: 32px 10px;
  max-width: 400px;
  transition: all 0.2s;
}

.buttonPrimary:hover {
  transform: scale(1.03);
  box-shadow: 5px 5px 10px -2px #ccc;
  cursor: pointer;
}

.buttonPrimary:active {
  transform: scale(1.02);
  box-shadow: 1px 1px 0px -10px #ccc;
  cursor: pointer;
}

.curLoc {
  background: #ddd;
  position: absolute;
  right: 0px;
  padding: 0;
  z-index: 990;
  border-radius: 5px;
  border: none;
  display: flex;
  margin: 0 0.5rem 0 0;
  top: 50%;
  transform: translateY(-50%);
}

.curLocImg {
  padding: 10px;
  cursor: pointer;
}

.curLocImg path {
  fill: white;
}

.location-search-input {
  font-size: 16px;
  color: #555;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif;
}

.location-search-input::placeholder {
  font-size: 16px;
  color: #999;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
}

.autocompleteContainer,
.autocomplete-dropdown-container {
  font-size: 16px;
  color: #555;
  font-weight: 400;
}

.autocomplete-dropdown-container:empty {
  display: none;
}

.hngrButton-grey {
  background: #ccc;
  cursor: not-allowed;
  pointer-events: none;
  color: #a8a8a8;
}

.hngrButton-grey > * {
  color: #a8a8a8;
}

.hngrButton-grey:hover {
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
}

.loadingText,
.bg,
.loader {
  z-index: 999999;
  position: fixed;
}

.errorContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.checker__action-button--single {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 2rem;
}

.checker__cta-icon--disabled > path {
  fill: #a8a8a8;
}

@media only screen and (max-width: 800px) {
  .checkerPageContentContainer {
    max-width: 95%;
  }
}
