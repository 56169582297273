@import "../../fileConstants/_index";

.text {
  font-size: 1rem;
  line-height: 1.2;
  font-family: "Nunito Sans", sans-serif;
}

.text--xs {
  font-size: 0.6rem;
}

.text--s {
  font-size: 0.75rem;
}

.text--m {
  font-size: 1rem;
}

.text--l {
  font-size: 1.25rem;
}

.text--xl {
  font-size: 1.5rem;
}

.text--header {
  font-size: 2rem;
}

.text--regular {
  font-weight: 400;
}

.text--bold {
  font-weight: bold;
}

.text--extrabold {
  font-weight: 700;
}

.text--heavy {
  font-weight: 800;
}

.text--black {
  font-weight: 900;
}

// .text--color-blue {
//   //   color: var(--p-blue);
// }

.text--color-grey {
  color: #888888;
}

.text--color-white {
  color: white;
}

.text--color-black {
  color: black;
}

// .text--color-pink {
//   //   color: var(--p-pink);
// }

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}
