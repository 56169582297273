.thank-you-for-dining{
  font-weight:bold;
  margin-top:80px;
  width:100%;
  text-align:center;
}

.amount-container{
  margin-top:40px;
}

.text-align-center{
  text-align:center;
}

.amount{
  color:#5DB075;
  font-size:36px;
  font-weight:900;
}

.payment-methods-container{
  width:100%;
  margin-top:40px;
}

.payment-method-container{
  width:80vw;
  height:100px;
  background-color:#F3F3F3;
  border-radius:10px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin: 0px auto;
}

.selected-payment{
  border:3px solid orange;
}

.card-section{
  background-color:#F3F3F3;
  margin-top:30px;
  padding-left:20px;
  padding-right:20px;
  padding-top:12px;
  padding-bottom:12px;
}

.elements-container{
  display:flex;
  flex-direction:column;
}

.element-row{
  display:flex;
  flex-direction:row;
  padding-top:4px;
  padding-bottom:4px;
}

.StripeElement--empty, .StripeElement--focus, .StripeElement--complete, .StripeElement--invalid {
  height:27px;
  background-color:white;
  border: 1px solid #d5d0d0;
  border-radius:5px;
  padding:5px;
}

.zipcode::placeholder{
  font-size:20px;
  color:#aab7c4;
  font-weight:light;
  font-family:sans-serif !important;
}


.card-number-input{
  width:95%;
}

.cvv-input{
  width:90%;
}

.expiration-input{
  width:90%;
}

.elements-header{
  font-weight:bold;
  padding-bottom:7px;
}

.zipcode{
  font-size:20px;
  padding-top:5px;
  text-align:left;
  color: #424770;
  font-family:sans-serif !important;
}   

.tip-section{
  padding-left:20px;
  padding-right:20px;
  padding-bottom:60px;
  margin-top:20px;
}

.tip-header{
  padding-left: 12px; 
  font-weight:bold;
  margin-bottom:8px;
}

.tip-container{
  flex-direction:row;
  display:flex;
  justify-content:space-around;
}

.tip-outer{
  display:flex;
  height:17vw;
  width:17vw;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  border:1px solid #d5d0d0;
  border-radius:5px;
  cursor:pointer;
}

.tip-selected{
  border:3px solid orange;
}

.tip-selected div{
  font-weight:bold;
}

.custom-tip-modal{
  background-color:#FAFAFA;
  width:70vw;
}

.custom-tip-modal-bottom{
  display:flex;
  flex-direction:row;
}

.custom-tip-modal-top{
  height:120px;
  flex-direction:row;
  display:flex;
  justify-content:center;
  align-items:center;
  padding-left:5vw;
  padding-right:5vw;
}

.custom-tip-modal-bottom div {
  height:20px;
  width:50%;
  flex-direction:row;
  display:flex;
  justify-content:space-around;
  border-top: 1px solid  rgb(204, 204, 204);
  font-size:18px;
  padding:13px;
}

.custom-tip-ok{
  border-left: 1px solid  rgb(204, 204, 204);
  font-weight:bold;
}

.enter-tip-amount{
  width:100%;
  text-align:center;
  font-weight:bold;
  margin-bottom:15px;
  font-size:18px;
}


.custom-tip-input{
  font-size:18px;
  height:27px;
  background-color:white;
  border: 1px solid #d5d0d0;
  border-radius:5px;
  padding:5px;
  width:120px;
}

.custom-tip-input::placeholder{
  font-size:18px;
}

.payment-footer{
  position:fixed;
  bottom:0px;
  width:100%;
  border-radius:5px;
  height:50px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.payment-footer-disabled{
  background-color:#C9C9C9;
  pointer-events:none;
}

.payment-footer-enabled{
  background-color:#F07A22;
}

.payment-footer-pay-with-card{
  display:flex;
  justify-content:center;
  flex-direction:column;
  padding-left:15px;
  color:white;
}

.payment-footer-amount{
  color:white;
  padding-right:15px;
  display:flex;
  justify-content:center;
  flex-direction:column;
  font-weight:bold;
}

.loading-div{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.thank-you-top-section{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.grey-bar-bottom{
  border-bottom: 1px solid #EEEEEE;
  padding-bottom:25px;
}

.thank-you-container{
  padding-left:20px;
  padding-right:20px;
  padding-bottom:200px;
  margin-top:90px;
}

.thank-you-for-supporting-us{
  width:45vw;
}

.thank-you-for-supporting-us-top{
  font-weight:bold;
  font-size:22px;
}

.thank-you-for-supporting-us-bottom{
  margin-top:10px;
  font-size:18px;
}

.thank-you-checkmark-amount{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.thank-you-checkmark{
  text-align:right;
}

.thank-you-amount{
  font-weight: bold;
  font-size: 22px;
  color:#5DB075;
}

.thank-you-social-media-section{
  padding-top:25px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}


.thank-you-social-media-section a {
  margin-left:10px;
}

.thank-you-social-media-text{
  width: 65vw;
}

.thank-you-order-section{
  padding-top:25px;
}

.thank-you-order-text{
  font-size:18px;
  margin-bottom:10px;
}

.thank-you-order-link a{
  font-size:18px;
  text-decoration:underline;
  color: #F07A22;
  font-weight:bold;
}

.thank-you-email-section{
  position:fixed;
  bottom:0px;
  background-color:#F3F3F3;
  width:100%;
  border-radius:10px;
}



.thank-you-email-section-top-text{
  font-size:18px;
  font-weight:bold;
  padding-left:20px;
  padding-right:20px;
  padding-top:20px;
  padding-bottom:20px;
  border-radius:10px;
}

.thank-you-email-section input{
  font-size:18px;
  padding-top:5px;
  text-align:left;
  color: #424770;
  height:40px;
  box-sizing:border-box;
  background-color:white;
  border: 1px solid #d5d0d0;
  border-radius:5px;
  padding:5px;
  width:100%;
  margin-top:10px;
  margin-bottom:20px;
}

.thank-you-email-section-button{
  width:100%;
  text-align:center;
  font-weight:bold;
  font-size:18px;
  padding-top:10px;
  padding-bottom:10px;
  border-radius:10px;
  margin-bottom:8px;
}

.email-button-disabled{
  background-color: #C9C9C9;
  font-weight:bold;
  color:white;
  pointer-events:none;
}

.email-button-enabled{
  font-weight:bold;
  color:white;
  background-color: #F07A22;
}

.receipt-sending-loading{
  text-align:center;
}

.thank-you-receipt-sent-section{
  position:fixed;
  bottom:0px;
  background-color:#F3F3F3;
  width:100%;
  border-radius:10px;
  height:175px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}

.thank-you-receipt-sent-section{
  font-size:18px;
}

.thank-you-receipt-sent-section div{
  text-align:center;
}

.thank-you-receipt-sent-bottom-text{
  font-weight:bold;
}
