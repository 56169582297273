@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900");

::placeholder {
  color: #ccc;
}

.regularHotlink {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.modalBackground {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  z-index: 9999;
  overflow-x: hidden;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
  align-items: center;
  -webkit-align-itms: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.modalContainer{
  position: fixed;
  width: calc(92vw - 32px);
  max-height: calc(96vh - 32px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
  max-width: 800px;
  background: #fff;
  margin: auto;
  border: 0;
  border-radius: 3px;
  text-align: center;
  /* animation: slide-in-left 500ms; */
}

.backButton {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 25px;
  opacity: 0.3;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.3;
}

.header {
  font-weight: 900;
  font-size: 24px;
  color: #222;
  letter-spacing: 1px;
  text-align: center;
  line-height: 42px;
  margin: 24px auto;
  position: relative;
  display: inline-block;
}

.header_v2 {
  text-align: center;
  text-align: left;
  padding: 1px 13px;
  font-weight: 900;
  font-size: 24px;
  padding-top: 24px;
  color: #222;
  letter-spacing: 1px;
  line-height: 42px;
}

.secondaryLink {
  font-weight: 700;
  font-size: 18px;
  color: #999;
  margin-top: 16px;
  line-height: 24px;
  display: inline-block;
  cursor: pointer;
  transition: all .2s;
}

.secondaryLink:hover{
  transform: scale(1.01);
  text-shadow: 0px 5px 10px #eee;
  color: #4286f4 !important;
}

.finePrintTitle {
  font-weight: 900;
  font-size: 18.66px;
  text-align: left;
  margin-bottom: 12px;
}

.finePrintCheckbox {
  display: inline-block;
  width: 32px;
  padding-top: 4px;
}

.finePrintWithCheckbox {
  display: inline-block;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
}

.finePrintCheckboxContainer {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 11pt;
  color: black;
  letter-spacing: 0;
  text-align: center;
  max-width: 380px;
  padding-left: 10px;
}

.finePrint {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 11pt;
  color: black;
  letter-spacing: 0;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 400px;
  margin: auto;
}

.finePrintBottom {
  font-weight: 500;
  font-size: 11pt;
  color: black;
  letter-spacing: 0;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 400px;
  margin: auto;
  margin-bottom: 30px;
}



.finePrint b a {
  color: #4286f4;
  cursor: pointer;
}

.inputContainer {
  position: relative;
  padding: 0 20px;
  transition: transform 500ms ease-in;
  /* animation: slide-in-left 500ms; */
  max-width: 400px;
  margin: auto;
}

.inputContainerExit {
  position: relative;
  padding: 2vh 20px;
  transition: transform 500ms ease-out;
  /* animation: slide-out-right 500ms; */
}

.modalError {
  font-weight: 500;
  font-size: 16px;
  color: red;
  letter-spacing: 2px;
  text-align: center;
  line-height: 24px;
  margin-top: 8px;
  text-align: left;
  padding: 1px 13px;
}

.inputLabel {
  font-weight: 500;
  font-size: 16px;
  color: #222222;
  letter-spacing: 2px;
  text-align: center;
  line-height: 24px;
  margin-top: 8px;
  text-transform: uppercase;
  text-align: left;
  padding: 1px 13px;
}

.inputBody {
  position: relative;
  background: #f1f1f1;
  border-radius: 10px;
  border: 0;
  height: 30px;
  padding: 10px 20px;
  margin: 0px 10px;
  font-size: 18px;
  /*width: calc(100% - 60px);*/
}

#inputPhone {
  padding: 10px 20px 10px 45px;
  width: calc(100% - 85px);
}

.inputPhonePrefix {
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  z-index: 999;
  top: 41px;
  left: 43px;
}

.AccountButton {
  position: relative;
  color: white;
  border-radius: 8px;
  border: 0;
  padding: 14px 30px;
  width: calc(100% - 20px);
  font-weight: 900;
  font-size: 16px;
  margin: 32px 10px;
  max-width: 400px;
  transition: all 0.2s;
}

.AccountButton:hover {
  transform: scale(1.03);
  box-shadow: 5px 5px 10px -2px #ccc;
  cursor: pointer;
}

.AccountButton:active {
  transform: scale(1.02);
  box-shadow: 1px 1px 0px -10px #ccc;
  cursor: pointer;
}

.codeInput {
  position: relative;
  border: 0;
  height: 10vh;
  width: 40%;
  font-size: 8vh;
  display: inline-block;
  padding: 0;
  margin: 20px 20px;
  text-align: center;
  box-shadow: 0px 15px 0px -10px #ddd;
  -webkit-appearance: none;
}

.codeInput:focus {
  box-shadow: 0px 15px 0px -10px #f5a623;
}

.errorText {
  position: relative;
  display: inline-block;
  font-size: 2vh;
  font-weight: bold;
  border-radius: 4px;
  background-color: #e45647;
  color: white;
  text-align: center;
  width: 60%;
  padding: 12px;
  margin-top: 5px;
}

.loginButtons {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.appleButton {
  background-color: black;
  color: white;
}

.oauthButton .oauthFiller {
  flex: 1;
}

.oauthButton .oauthContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.oauthButton:hover{
  transform: scale(1.01);
}

.emailButton {
  color: white;
}

.oauthButton .buttonText {
  display: flex;
  justify-content: center;
  margin-left: 16px;
}

.appleButton .buttonText {
  margin-left: 17px;
}

.oauthButton {
  align-self: center;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  border-radius: 4px;
  padding: 4px;
  margin: 8px 0px 8px 0px;
  min-width: 140px;
  width: 90%;
  font-weight: 800;
  min-height: 38px;
  height: 38px;
  align-items: center;
  justify-content: left;
  flex: 1 auto 1;
}

.googleButton img {
  background-color: white;
  border-radius: 10px;
}

.oauthButton img {
  height: 22px;
  justify-content: center;
}

.fakeImage {
  width: 22px;
  height: 22px;
}

.solanaButton {
  background-color: black;
  color: white;
}

.facebookButton {
  background-color: #395692;
  color: white;
}

.googleButton {
  background-color: #4285f4;
  color: white;
}

.confirmationContainer {
  margin: 20px 30px;
  /* animation: slide-in-left 500ms; */
}

.confirmationEmoji {
  height: 120px;
  margin: -30px 0px 10px 0px;
}

.confirmationPrimaryText {
  font-size: 32px;
  font-weight: 300;
  color: #bbb;
}

.confirmationSecondaryText {
  font-size: 18px;
  font-weight: 300;
  color: #777;
  margin: 5px;
}

.statusDotsContainer {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 140px;
}

.statusDotFilled {
  background: #0b6829;
  height: 12px;
  width: 12px;
  margin: 8px;
  border-radius: 50%;
  display: inline-block;
}

.statusDotUnfilled {
  background: #bbb;
  height: 12px;
  width: 12px;
  margin: 8px;
  border-radius: 50%;
  display: inline-block;
}

/* @keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translate(100%);
  }
  to {
    opacity: 1;
    transform: translate(0%); 
  }
}

@keyframes slide-out-right {
  from {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  to {
    transform: translate(-100%, 0%);
    opacity: 0;
  }
}

@keyframes slide-in-up {
  from {
    transform: translate(0%, 100%);
  }
  to {
    transform: translate(0%, 0%);
  }
} */
