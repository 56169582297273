.credit-card-fee-header {
  font-weight: 600;
  font-size: 16px;
}

.credit-card-fee-description {
  color: #595959;
  font-weight: 400;
  font-size: 14px;
}

.payment-method-fee {
  align-items: center;
  background: #f8f8f8;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
}

.payment-method-fee > svg,
.payment-method-fee > img {
  margin-right: 1rem;
}

.payment-method-blue {
  background-color: #e5e7ff;
}

.payment-method-green {
  background-color: #e2efe3;
}

.payment-method-yellow {
  background-color: #fff4cc;
}

.payment-method-red {
  background-color: #fddad9;
}
