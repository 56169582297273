$fc-black: #22252b;
%text-rules {
  font-family: "Nunito Sans", sans-serif;
}

%headers {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  color: $fc-black;
}

%hover {
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    transform: scale(1.05, 1.05);
  }
}

@for $i from 1 through 10 {
  $margin: 8px * $i;

  .m_#{$i} {
    margin: $margin;
  }
  .mt_#{$i} {
    margin-top: $margin;
  }
  .mb_#{$i} {
    margin-bottom: $margin;
  }
  .mr_#{$i} {
    margin-right: $margin;
  }
  .ml_#{$i} {
    margin-left: $margin;
  }
}
