@import "_globals";
@import "_helpers.scss";

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-apart {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}
.flex-align-start {
  align-items: flex-start;
}

.flex-align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex-grow: 1;
}

.left {
  text-align: left;
}

.p-relative {
  position: relative;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

.mv-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mh-auto {
  margin-left: auto;
  margin-right: auto;
}

.a-underline {
  text-decoration: underline;
}

.w100 {
  width: 100%;
}

.nowrap {
  white-space: nowrap;
}
.hidden {
  visibility: hidden;
}
