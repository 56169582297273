.Cart{
  position: fixed;
  bottom: 0px;
  left: 0;
  margin: 0px 8px;
  width: calc(100% - 16px);
  text-align: center;
  transition: all .5s ease;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  padding: 32px 16px 96px 16px;
  width: calc(100% - 48px);
  box-shadow: 0px 8px 16px -4px rgba(0,0,0,.4);
  z-index: 998;
  animation: slide-up-from-bottom .5s forwards ease;
  max-height: 78vh;
  overflow-y: auto;
}

.Cart::before{
  content: '';
  position:absolute;
  height: 4px;
  width: 50%;
  border-radius: 4px;
  background: #eee;
  top: 16px;
  left: 25%;
}

.CartDesktop{
  position: fixed;
  width: 296px;
  right: 0;
  top: 0;
  padding: 8px;
  margin: 144px 12px;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #fff;
  vertical-align: top;
  height: calc(100vh - 172px);
  overflow-y: auto;
}

.CartClose{
  position: absolute;
  top: 12px;
  right: 12px;
  height: 20px;
  opacity: .8;
  cursor: pointer;
  z-index: 12;
  border-radius: 50%;
  background: #fff;
  padding: 4px;
}

.CartBackground{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.2);
  z-index: 997;
}

.CartFooter{
  position: fixed;
  left: 16px;
  width: calc(80% - 32px);
  font-size: 18px;
  padding: 16px 8px;
  margin-bottom: 22px;
  font-weight: 900;
  letter-spacing: .5px;
  color: #fff;
  background: #4284f4;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 8px 16px -4px rgba(0,0,0,.4);
  z-index: 996;
  cursor: pointer;
  animation: slide-up-from-bottom .5s forwards ease;
}

.CartCheckerButton{
  width: calc(100% - 16px);
  margin: 0px 8px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px -4px rgba(0,0,0,.2);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  vertical-align: bottom;
  margin-bottom: 16px;
  cursor: pointer;
  background: #ccc;
}

.CartCheckerText{
  line-height: 1;
  vertical-align: middle;
  text-transform: capitalize;
  display: inline-block;
  text-align: left;
}

.CartCheckerIcon{
  vertical-align: middle;
  display: inline-block;
  height: 32px;
  margin: 8px;
}

.CartIcon{
  height: 24px;
  position: absolute;
  left: 15px;
  top: 15px;
}

.CartHeader{
  text-align: left;
  font-weight: 900;
  font-size: 32px;
  color: #222;
  padding: 12px 8px;
}

.CartCheckout{
  position: absolute;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  font-weight: 900 !important;
  text-transform: none !important;
  bottom: 12px;
  left: 8px;
  width: calc(100% - 96px);
  padding: 12px 0px !important;
}

.CartItemsWrapper{
  position: relative;
  max-height: calc(100vh - 372px);
  overflow-y: auto;
}

.NoIntercom{
  width: calc(100% - 24px);
}

@media only screen and (max-width: 800px){
  .CartCheckout{
    position: fixed;
    bottom: 22px;
    left: 16px;
    width: calc(80% - 32px);
    font-size: 18px;
    padding: 16px 8px;
    font-weight: 900;
    letter-spacing: .5px;
    color: #fff;
    background: #4284f4;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 8px 16px -4px rgba(0,0,0,.4);
    z-index: 998;
    cursor: pointer;
  }
  .NoIntercom{
    width: calc(100% - 32px);
  }
}

@keyframes slide-up-from-bottom {
  from{
    bottom: -100%;
  }
  to{
    bottom: 0px;
  }
}













.cartContainer{
  height: calc(100vh - 120px);
  width: calc(100% - 24px);
  padding: 0px 12px 120px 12px;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 800px) {
  .cartContainer{
    width: 72%;
    float: right;
  }
}

#cartExit {
  animation: exit-cart .2s ease-out;
  overflow-x: hidden;
  white-space: nowrap;
}

@keyframes exit-cart {
  from{
    width: 226px;
    /* padding: 0px 12px 120px 12px; */
  }
  to{
    width: 0px;
    /* padding: 0px 0px 120px 0px; */
  }
}

.editPickupOrDelivery{
  position: relative;
  display: inline-block;
  margin: auto;
  text-align: center;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 20px;
}

.cartCheckoutTop{
  font-size: 12px;
  padding: 12px 8px;
  display: inline-block;
  position: relative;
}

.cartCheckoutBottom{
  margin: 24px auto;
  display: inline-block;
  position: relative;
}

.cartHeader{
  font-weight: 800;
  font-size: 48px;
  color: #111;
  padding: 12px 0px;
}

#noHover{
  cursor: not-allowed;
  background: #ccc;
  color: #fff;
}

#noHover:hover{
  transform: scale(1);
  box-shadow: 1px 5px 20px -2px #ccc;
}

.CartItemContainer{
  position: relative;
  width: calc(100% - 16px);
  overflow: hidden;
  padding: 8px 4px;
  margin: 0px 4px;
  text-align: left;
  border-bottom: 1px solid #eee;
  transition: all .2s;
}

.checkout-section .CartItemContainer{
  position: relative;
  overflow: hidden;
  width:100%;
  text-align: left;
  border-bottom: 1px solid #eee;
  transition: all .2s;
  border: 1px solid #C9C9C9;
  box-sizing: border-box;
  border-radius: 8px;
  padding:15px 15px;
  margin:0px;
}


.CartItemContainer--thankyou{
  margin: 0px !important;
  padding: 0px !important;
  font-size:14px !important;
}

.CartItemContainer--thankyou:hover{
  background: white !important;
  cursor: default !important;
}

.CartItemName{
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 900;
  color: #222;
  vertical-align: top;
  width: calc(100% - 54px);
}

.CartItemName-new{
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.CartItemPrice{
  color: green;
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  top: 12px;
  right: 4px;
  vertical-align: top;
}

.CartItemModifier{
  font-size: 12px;
  font-weight: 500;
  color: #666;
  line-height: 1.2;
}

.CartRemoveItem{
  position: absolute;
  right: 0px;
  top: 4px;
  margin-right: 4px;
  font-size: 16px;
  font-weight: 900;
  background: #ea4335;
  color: #fff;
  padding: 4px 8px;
  cursor: pointer;
  vertical-align: top;
  border: 0;
  border-radius: 4px;
  animation: slide-in-from-right .2s forwards ease;
}

.CartItemSpecialInstructions{
  color: #4284f4;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  /*padding-left: 24px;*/
  letter-spacing: -.5px;
}

@keyframes slide-in-from-right {
  from{
    opacity: .8;
    transform: translateX(100%);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
}

.CartItemEditRemoveContainer{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-end;
}

.CartItemLink{
  text-decoration:underline;
  font-size:14px;
  padding:5px;
  cursor:pointer;
}

.checkout-cart-item-price-row{
  display:flex;
  align-items:top;
  justify-content:space-between;
}

.CartItemPrice--checkout{
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
