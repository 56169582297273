.button-wrapper__container {
  background: transparent;
  padding: 0;
  cursor: pointer;
  border: none;
  box-sizing: border-box;
}

.button-wrapper__container--border {
  border: inherit;
}

.button-wrapper__container:not(.button-wrapper--propagate) > * {
  pointer-events: none;
}
