.checkout-header-row {
  display: flex;
  flex-direction: row;
  background-color: #f3f3f3;
  position: fixed;
  z-index: 996;
  width: 100vw;
  overflow-y: scroll;
  top: calc(60px + var(--supperclub-back-height) + var(--inset-top));
}

.checkout-container {
  padding-bottom: 15px;
}

@media only screen and (min-width: 920px) {
  .checkout-container {
    width: 430px;
  }
}

@media only screen and (max-width: 920px) {
  .finance-section {
  }
}

.pickup-delivery-container-desktop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  margin-top: 18px;
}

.pickup-delivery-container-row-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pickup-delivery-container {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding: 9px;
  font-weight: bold;
  font-size: 14px;
}

.pickup-delivery-icon-container {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pickup-delivery-icon-container svg {
  fill: black;
  width: 24px;
  height: 24px;
}

.pickup-delivery-icon-container * {
  fill: black;
}

.leaf-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 50px;
  margin-right: 8px;
  background-color: #149309;
  display: inline-block;
  margin-left: 11px;
}

.leaf-container svg {
  position: relative;
  left: 5px;
  fill: white;
}

.pickup-delivery-text {
  font-size: 18px;
  font-weight: 600;
  margin-left: 8px;
}

.header-row-modal {
  background-color: #fafafa;
  padding-bottom: 30px;
  z-index: 995;
  padding-top: 15px;
}

@media only screen and (max-width: 920px) {
  .header-row-modal {
    padding-left: 15px;
    width: calc(100% - 30px);
    padding-right: 15px;
    padding-top: 0px !important;
  }
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  z-index: 990;
  position: fixed;
  width: 100vw;
  overflow: hidden;
}

.modal-overlay input {
  background-color: rgba(0, 0, 0, 0.5) !important;
  opacity: 0.1;
}

.header-row-modal-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}

.header-row-modal-top-row-left {
  font-weight: bold;
  font-size: 22px;
}

.edit-text {
  font-size: 15px;
  text-decoration: underline;
  color: #ea0170;
  color: var(--rest-secondary);
  cursor: pointer;
}

.pink-bottom {
  border-bottom: 2px solid #ea0170;
}

.curLocImg {
  padding: 10px;
  cursor: pointer;
}

.location-search-input {
  font-size: 16px;
  color: #555;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif;
}

.location-search-input {
  font-size: 16px;
  color: #555;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif;
}

.location-search-input::placeholder {
  font-size: 16px;
  color: #999;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
}

.autocompleteContainer,
.autocomplete-dropdown-container {
  font-size: 16px;
  color: #555;
  font-weight: 400;
}

.autocomplete-dropdown-container:empty {
  display: none;
}

.header-row-autocomplete-container {
  position: relative;
  width: 100%;
  max-width: 440px;
  margin-bottom: 1rem;
  z-index: 3;
  display: flex;
  align-items: center;
}

.checkout-label {
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 920px) {
  .checkout-label {
    font-size: 14px !important;
  }
}

.header-input {
  padding: 10px 15px;
  width: 100%;
  border-radius: 6px !important;
  border: 1px solid #a8a8a8 !important;
  display: inline-block;
  height: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 0px red;
  -webkit-appearance: none;
  font-family: "Nunito Sans", sans-serif;
}

.header-row-address2-section {
  margin-top: 20px;
}

.save-changes-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.header-row-save-changes-button {
  width: 50%;
  color: white;
  font-size: 16px;
  font-weight: 900;
  margin-top: 20px;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 15px;
  background-color: var(--rest-primary);
}

.header-row-save-changes-button svg {
  fill: white;
  position: relative;
  top: 3px;
  margin-left: 4px;
}

.header-row-save-changes-button--disabled {
  pointer-events: none;
  background: #ccc !important;
  cursor: not-allowed !important;
  color: #a8a8a8 !important;
  border-width: 0px !important;
}

.header-row-save-changes-button--disabled svg {
  fill: #a8a8a8 !important;
}

.pickup-delivery-container-desktop-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.checkout-section-header {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 15px;
}

.checkout-section-header-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616161;
}
.checkout-section-subheader {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.checkout-content {
  background-color: #fafafa;
}

.checkout-wallet-tokens {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  margin: 10px 0px;
  gap: 4px;
  background: #f4f4f4;
  border-radius: 10px;
}

.checkout-wallet-tokens-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  background: #424242;
  color: #ffffff;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.checkout-wallet-tokens-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  background: #f4f4f4;
  color: #262525;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-weight: 700;
}

.checkout-wallet-tokens-row img {
  max-width: 25px;
  padding-right: 10px;
}

.checkout-wallet-tokens-row-amount {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #239c44;
}

.checkout-wallet-remaining {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  margin: 10px 0px;
  gap: 4px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

.checkout-wallet-remaining-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  color: #262525;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-weight: 700;
}

.checkout-wallet-remaining-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #f4f4f4;
  padding: 10px;
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.checkout-wallet-remaining-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 8px;
  background: #f4f4f4;
  border-radius: 4px;
}

.checkout-address-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px !important;
  font-family: "Nunito Sans", sans-serif;
}

.time-select-container {
  padding: 9px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.schedule-for {
  font-size: 22px;
  font-weight: 900;
}

.checkout-section {
  width: 100%;
  background-color: white;
  padding: 16px;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 10px;
}

.checkout-bottom-border {
  padding-bottom: 15px;
  border-bottom: 3px solid #f3f3f3;
}

.checkout-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-start {
  justify-content: flex-start;
}

.space-between {
  justify-content: space-between;
}

.saved-card-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #717171;
  box-sizing: border-box;
  border-radius: 10px;
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  color: #717171;
  cursor: pointer;
}

.saved-card-container svg {
  fill: #717171;
}

.saved-card-container-edit {
  width: calc(100% - 50px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #717171;
  box-sizing: border-box;
  height: 60px;
  margin-top: 10px;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 15px;
}

.saved-card-container-selected {
  background: rgba(0, 10, 255, 0.1);
  color: black;
  border: 3px solid #000aff;
  padding-left: 13px;
  padding-right: 13px;
}

.saved-card-container-selected svg {
  fill: black;
}

.plus-box-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 5px;
  margin-right: 15px;
}

.bold-text {
  font-size: 15px;
  color: var(--rest-secondary);
  font-weight: bold;
  cursor: pointer;
}

.checkout-tip-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  height: 57px;
  padding: 0px 15px;
}

.checkout-tip-outer-mobile {
  height: 17vw;
  width: 17vw;
  box-sizing: border-box;
}

.checkout-tip-outer-desktop {
  box-sizing: border-box;
  min-width: 80px;
}

.checkout-tip-selected {
  font-weight: 700;
  color: #000aff;
  background: rgba(0, 10, 255, 0.1);
  border: 4px solid #000aff;
}

.checkout-tip-selected div {
  font-weight: bold;
}

.checkout-custom-tip-modal {
  background-color: #fafafa;
  width: 70vw;
}

.checkout-custom-tip-modal-bottom {
  display: flex;
  flex-direction: row;
}

.checkout-custom-tip-modal-top {
  height: 120px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
}

.checkout-custom-tip-modal-bottom div {
  height: 20px;
  width: 50%;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid rgb(204, 204, 204);
  font-size: 18px;
  padding: 13px;
}

.checkout-custom-tip-ok {
  border-left: 1px solid rgb(204, 204, 204);
  font-weight: bold;
}

.checkout-enter-tip-amount {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 18px;
}

.custom-tip-input-dollar-sign {
  font-size: 18px;
  height: 27px;
  position: relative;
  left: 15px;
}

.checkout-custom-tip-input-fake {
  position: absolute;
  z-index: 99;
  font-size: 18px;
  height: 27px;
  line-height: 29px;
  background-color: white;
  border: 1px solid #d5d0d0;
  border-radius: 5px;
  padding: 5px;
  width: 120px;
  padding-left: 17px;
}

.checkout-custom-tip-input {
  font-size: 18px;
  height: 27px;
  background-color: white;
  border: 1px solid #d5d0d0;
  border-radius: 5px;
  padding: 5px;
  width: 120px;
  padding-left: 17px;
}

@media only screen and (max-width: 920px) {
  .checkout-custom-tip-input {
    caret-color: transparent;
  }
}

.checkout-custom-tip-input::placeholder {
  font-size: 18px;
}

.checkout-tip-container {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.add-more-items {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-more-items-text {
  cursor: pointer;
  color: var(--rest-secondary);
  text-decoration: underline;
  position: relative;
  margin-left: 15px;
}

.checkout-mobile-cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.checkout-mobile-cart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.checkout-mobile-cart-row {
  color: #717171;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.mobile-cart-total-row {
  font-size: 18px;
  color: black;
}
.bold900 {
  font-weight: 900;
}

.coupon-tag-container {
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
}

.deliveryButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 10px;
}

@media only screen and (max-width: 920px) {
  .deliveryButtonContainer {
    margin-top: 0px !important;
  }
}

.checkout__action-button {
  border-radius: 4px;
  position: relative;
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition: box-shadow 100ms linear;
  display: flex;
  flex-direction: column;
  flex-basis: 48%;
}

.checkout__cta {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 4px;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 1rem;
}

.checkout__cta:not(.hngrButton-grey) > svg {
  transition: transform 50ms linear;
}

.checkout__cta:not(.hngrButton-grey):hover > svg {
  transform: translateX(2px);
}

.secondary-bg {
  background-color: var(--rest-secondary);
}

.checkout__action-button:active {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.checkout__action-button:not(.checkout__action-button--selected) {
  color: #a8a8a8;
}

.checkout__action-button--selected {
  border-color: var(--rest-primary);
  border-width: 2px;
  border-style: solid;
  box-shadow: none;
}

.checkout__action-button--deactivated {
  border: none;
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: none;
  background-color: #e8e8e8;
  color: #a8a8a8;
}

.checkout__action-button--selected > .checkout__action-button-circle {
  border: 1px solid var(--rest-primary);
  background: var(--rest-primary) content-box;
  padding: 0.1rem;
  /* animation: grow-in 250ms cubic-bezier(0.16, 1, 0.3, 1); */
}

.checkout__action-button-circle {
  width: 16px;
  height: 16px;
  transition: background 150ms linear;
  border: 1px solid #a8a8a8;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.65rem;
}

.checkout__icon > path {
  fill: #a8a8a8;
}

.checkout__icon--selected > path {
  fill: var(--rest-primary);
}

.checkout__icon--deactivated > path {
  fill: #a8a8a8;
}

.checkout-footer {
  bottom: 0px;
  width: 100%;
  border-radius: 5px;
  height: calc(50px + var(--inset-bottom));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  font-size: 16px;
  z-index: 999;
  cursor: pointer;
}

.checkout-footer-bitcoin {
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999;
  margin-top: 15px;
}

.bitcoin-wont-be-charged {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.checkout-footer-bitcoin-button {
  bottom: 0px;
  width: 100%;
  border-radius: 5px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  z-index: 999;
}

.checkout-footer-mobile {
  position: fixed;
}

.checkout-footer svg {
  margin-right: 12px;
}

.checkout-footer-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  color: white;
  font-weight: bold;
}

.checkout-footer-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
  color: white;
  font-weight: bold;
}

.checkout-footer-enabled {
  background-color: var(--rest-primary) !important;
}

.checkout-footer-enabled-applepay {
  background-color: #22252b !important;
  color: white !important;
  justify-content: center !important;
  align-items: center;
  font-weight: bold;
}

.checkout-footer-disabled {
  background-color: #e0e0e0;
  cursor: not-allowed !important;
  pointer-events: none;
}

.loading-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.checkout-desktop-container {
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
}

.checkout-desktop-left {
  margin-right: 10px;
}

.checkout-desktop-left .checkout-footer {
  margin-top: 20px;
}

.checkout-desktop-right {
  width: 400px;
  position: fixed;
  top: 60px;
  bottom: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.checkout-desktop-right-inner {
  background-color: white;
  width: 400px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
}

.checkout-desktop-right .checkout-footer {
  margin-top: 20px;
}

.hidden-right-side {
  width: 430px;
  height: 10px;
  opacity: 0;
}

.pickup-delivery-text {
  font-weight: bold;
}

.pickup-delivery-container-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pickup-delivery-section-change-text {
  text-decoration: underline;
  color: #000aff;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.checkout-tip-inner-percent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkout-error-container {
  background-color: #ffe0e0;
  color: #d60000;
  padding: 12px;
  margin-bottom: 15px;
}

.desktop-cart-error {
  display: flex;
  flex-direction: row;
}

.checkoutCartRemoveItem {
  margin-right: 4px;
  font-size: 16px;
  font-weight: 900;
  background: #ea4335;
  color: #fff;
  padding: 4px 8px;
  cursor: pointer;
  vertical-align: top;
  border: 0;
  border-radius: 4px;
  -webkit-animation: slide-in-from-right 0.2s forwards ease;
  animation: slide-in-from-right 0.2s forwards ease;
}

.checkout-coupon-row {
  color: #37b370;
}

.checkout-coupon-applied-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.checkout-coupon-applied-top {
  color: #37b370;
  font-size: 16px;
}

.checkout-coupon-applied-remove {
  text-decoration: underline;
  font-size: 14px;
  margin-top: 8px;
  font-weight: bold;
  cursor: pointer;
}

.utensils-text {
  display: inline-block;
  font-size: 18px;
  padding-left: 10px;
}

.utensils-text-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.taxes-and-fees-text svg {
  position: relative;
  top: 4px;
  margin-left: 5px;
  cursor: pointer;
}

.tip-total {
  border: 2px solid black;
  border-radius: 7px;
  line-height: 22px;
  padding: 3px 5px;
}

.sharing-is-caring svg {
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.special-instructions-textarea {
  padding: 10px;
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 42px;
  line-height: 20px;
  border: 1px solid #ccc;
  border-radius: 6px !important;
  border: 1px solid #a8a8a8 !important;
  font-size: 16px;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 0px 0px red;
  -webkit-appearance: none;
  font-family: "Nunito Sans", sans-serif;
}

.placeholder-text {
  color: lightgray;
}

.continue-with-bitcoin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.continue-with-bitcoin-text {
  color: white;
  margin-left: 2px;
  font-size: 16px;
  font-weight: bold;
}

.checkout-footer-enabled-bitcoin {
  background-color: black;
}

@media only screen and (max-width: 920px) {
  .checkout-section {
    width: calc(100% - 40px);
    margin-bottom: 15px;
  }

  .checkout-content {
    padding-top: calc(var(--supperclub-back-height) + var(--inset-top));
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .checkout-payment-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .checkout-order-details-section {
    margin-top: 60px;
  }
}
.checkout-flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.checkout-section .coupon-tag-container span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #000aff;
}
.nft-coupon-applied-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: #262525;
  border-radius: 4px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #39f376 !important;
  background-color: black;
}

.nft-irazu-checkout-right-container {
  padding: 9px 25px;
  background: #262525;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Space Grotesk";
}

.nft-irazu-checkout-right-container img {
  height: 80px;
}

.nft-irazu-checkout-right-container-title {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #fcfcfc;
}

.nft-irazu-checkout-right-container-middle-txt {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 27px;
  color: #39f376;
}

.nft-irazu-checkout-right-container-bottom-txt {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #fcfcfc;
}

.nft-irazu-checkout-free {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: right;
  color: #39f376;
}

.qr-modal-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.qr-modal-subtitle {
  font-size: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.qr-checkout-modal-container {
  background-color: black;
  display: flex;
  height: 436px;
}

.qr-checkout-modal-left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-checkout-modal-left img {
  width: 100%;
  height: 100%;
}

.qr-checkout-modal-right {
  width: 50%;
  background: #f9f9f9;
  border-radius: 30px;
  padding: 57px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scan-to-pay {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #000000;
  margin-bottom: 12px;
}

.scan-to-pay-blurb {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 106.6%;
  text-align: center;
  color: #4f4d4d;
}

.scan-to-pay-qr-box {
  box-sizing: border-box;
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.scan-qr-total {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #aa32ff;
  margin-top: 16px;
}

.ReactModal__Overlay {
  z-index: 9999;
}

.checkout-kiosk-modal {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.kiosk-modal-image {
  width: 70vw;
}

.checkout-kiosk-modal-title {
  font-style: normal;
  font-weight: bold;
  white-space: nowrap;
  font-size: 40px;
  color: #000000;
}

.checkout-kiosk-modal-header {
  background-color: #1c1c1c;
  position: absolute;
  top: 0px;
  height: 80px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.checkout-kiosk-modal-back {
  color: white;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.checkout-kiosk-modal-back * {
  fill: white;
}

.checkout-kiosk-modal-back svg {
  margin-right: 10px;
}

.kiosk-order-number-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 40px;
  width: 200px;
  height: 200px;
  background: #eeeeee;
}

.kiosk-success-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.self-destruct-message {
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.checkout-kiosk-modal-title * {
  fill: green;
  width: 80px;
  height: 80px;
}

.checkout-mobile-cart-discount {
  color: #239c44;
}

.debit-discount-applied {
  background-color: #e2efe3;
  color: #239c44;
  font-size: 14px;
  margin-top: 1rem;
  padding: 0.5rem;
  text-align: center;
}
