/* container for elements within white border */

html {
  font-family: "Nunito Sans", sans-serif;
}



.menuItemBackground {
  margin: auto;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 84px);
  padding-bottom: 84px;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  z-index: 998;
  overflow: hidden;
}

@media only screen and (min-height: 600px) {
  #menuItemBackgroundIphoneX {
    padding-bottom: 132px;
    height: calc(100vh - 132px);
  }
}

.menuItemContainer {
  position: relative;
  height: auto;
  max-height: calc(84vh - 32px);
  width: calc(95vw - 32px);
  max-width: 800px;
  background: #fff;
  padding: 16px 16px 0px 16px;
  border-radius: 20px;
  -webkit-animation: drop-in ease 500ms forwards;
  -moz-animation: drop-in ease 500ms forwards;
  -o-animation: drop-in ease 500ms forwards;
  animation: drop-in ease 500ms forwards;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999;
  -webkit-overflow-scrolling: touch;
}
.menuItemClose {
  position: absolute;
  z-index: 999;
  top: 16px;
  right: 16px;
  width: 24px;
  background: #ffffffcc;
  border: none;
  cursor: pointer;
  opacity: 0.4;
  border-radius: 8px;
}

.menuItemAddToCart {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  left: 0px;
  padding: 12px 0px;
  width: 100%;
  background: #fff;
  border-radius: 16px;
}

.menuItemAddToCartEdge {
  bottom: 0px;
  left: 0px;
  padding: 12px 0px;
  width: 100%;
  background: #fff;
  border-radius: 16px;
}

.menuItemAddToCart::before {
  content: "";
  width: 100%;
  height: 30px;
  background: linear-gradient(
    to bottom,
    rgba(254, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.74) 50%,
    white 100%
  );
  border-bottom: 1px solid #eee;

  position: absolute;
  top: -30px;
}

.menuItemName {
  position: relative;
  font-weight: bold;
  font-size: 4vh;
  width: calc(100% - 24px);
  padding-right:24px
}

.modifier__name {
  padding-left: 8px;
    padding-right: 8px;
}
.modifier__name--selected {
  font-weight: 800;
}
