@import "../../css/foodcoinStandards.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 12px 0;
  width: 100%;

  @extend %text-rules;
}

.subheader {
  color: #858e96;
  font-size: 12px;
  line-height: 1.5;
  margin: 6px 0;
}

.childInput {
  width: 100%;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 6px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $fc-black;
}

.childInput > div {
  width: 100%;
}

.input {
  -webkit-appearance: none;
  color: $fc-black;
  font-weight: 400;
  border: 1px solid #eee;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 16px;
  padding: 12px;
  width: 100%;
  line-height:1
}

.input::placeholder {
  color: #ced4da;
}
