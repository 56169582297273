@import "../../../css/foodcoinStandards.scss";

.brand-rest-location__container {
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 14px;
  margin: 12px;
  width: 300px;
  display: flex;
  flex-direction: column;

  & > * {
    color: $fc-black;
  }
}

.brand-rest-location__rest-info-container {
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  & > div {
    width: 50%;
  }
}

.brand-rest-location__button-container {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.brand-rest-location__rest-info {
  display: flex;
  flex-direction: column;
}

.brand-rest-location__telephone {
  text-decoration: underline;
}

.brand-rest-location__hours--test {
  color: #888;
  text-align: right;
}

.brand-rest-location__hours {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  justify-content: center;
}

.brand-rest-location__hours--open {
  color: #2a6b2a;
}

.brand-rest-location__hours--closed {
  color: #6f2424;
}

.brand-rest-location__body-text {
  font-size: 14px;
}

.brand-rest-location__rest-name {
  font-size: 20px;
  font-weight: 900;
  display: flex;
  justify-content: space-between;
}

.brand-rest-location__distance {
  color: #888;
  text-align: right;
  font-size: 16px;
  font-weight: initial;
}

.brand-rest-location__order-button,
.brand-page__current-location {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #4284f4;
  color: white;
  padding: 8px 18px;
  border-radius: 8px;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
}

.brand-rest-location__order-button {
  margin-top: 8px;
  flex-basis: 35%;
}

@media only screen and (max-width: 400px) {
  .brand-page__current-location {
    font-size: 12px;
  }
  .brand-rest-location__order-button {
    padding: 6px 12px;
  }
}
