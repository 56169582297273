
.addCoupon{
  text-align: center;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 8px;
}

.couponContainer{
  position: relative;
  padding: 4px 24px;
  width: calc(100% - 48px);
  text-align: center;
  display: inline-block;
}

.inputBody{
  position: relative;
  background: #F1F1F1;
  border-radius: 10px;
  border: 0;
  padding: 10px 20px;
  font-size: 14px;
  width: calc(100% - 40px);
}

.submitButton{
  padding: 10px;
  font-size: 14px;
  font-weight: 900;
  position:absolute;
  top: 4px;
  right: 24px;
  color: white;
  border-radius: 0px 10px 10px 0px;
  border: 0px;
  z-index: 99;
  cursor: pointer;
}

.yourCode{
  font-size: 12pt;
  font-weight: 500;
  color: #999;
  width: 100%;
  text-align: center;
}

.removeButton{
  display: inline-block;
  height: 16px;
  margin: 12px 6px;
  opacity: .3;
  vertical-align: -70%;
}

