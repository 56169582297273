.MenuContainer {
  padding: 0px 24px;
}

.MenuSearch {
  display: inline-block;
  max-width: 360px;
  vertical-align: middle;
  font-size: 14px;
  height: 32px;
  margin-left: 24px;
}

.MenuSearch > div {
  margin: 0 !important;
}

@media only screen and (max-width: 800px) {
  .MenuContainer {
    padding: 0px 8px 64px 8px;
  }
  .MenuSearch {
    margin-left: 0px;
  }
}

.menu-category-container{
  max-width:1200px;
}

@media only screen and (min-width: 801px){
  .menu-category-container{
    margin:0px auto;
  }
}

.MenuCategoryHeader {
  font-size: 32px;
  padding: 64px 8px 4px 8px;
  color: #222;
  font-weight: 900;
}

.MenuCategoryUnderline {
  height: 4px;
  width: 48px;
  margin-left: 8px;
}


.MenuCategoryHeader-featured{
  padding:32px 0px 0px 8px !important;
}

.MenuCategoriesDesktop {
  position: fixed;
  top: 60px;
  left: 0;
  height: 48px;
  width: calc(100vw - 16px);
  background: #fff;
  padding: 8px;
  z-index: 996;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.MenuCategoriesDesktop > div {
  display: inline-block;
}

.MenuCategoriesDesktop::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 32px;
  width: calc(100% - 64px);
  height: 1px;
  background: #eee;
}

.MenuCategorySelector {
  display: inline-block;
  vertical-align: top;
  padding: 8px 16px 24px 16px;
  cursor: pointer;
  transition: all 0.2s;
}

.MenuMore {
  position: absolute;
  right: 0px;
  top: 20px;
  background: #fff;
  cursor: pointer;
  padding: 0px 16px;
  animation: fade-in 0.5s forwards ease;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.MenuMore::before {
  content: "";
  position: absolute;
  left: -96px;
  top: 0px;
  width: 96px;
  height: 100%;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

.MenuMore:hover {
  color: #4284f4;
}

.MenuCategorySelector:hover > div {
  color: #4284f4;
}

.MenuCategorySelectorImg {
  display: inline-block;
  width: 24px;
  vertical-align: middle;
  padding-right: 8px;
}

.MenuCategorySelectorBody {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 900;
  transition: all 0.2s;
}


.SecondaryMenuContainer{
  position: absolute;
  top: 48px;
  right: 16px;
  z-index:5;
}

.SecondaryMenu {
  display:inline-block;
  margin-left:15px;
  color: #4284f4;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 700;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.2s;
}


@media only screen and (max-width: 800px) {
  /*
  .SecondaryMenuContainer{
    position:absolute;
    text-align:right;
    top: 20px;
  }*/
  .SecondaryMenuContainer{
    position: relative;
    text-align: right;
  }
}

.MenuCategoryDescription {
  font-weight: 700;
  font-size: 14px;
  padding: 16px 8px;
  margin: 0;
  margin-right: 16px;
  line-height: 1.6;
}

.MenuCategoryDescription-featured{
  padding: 16px 0px;
  margin-right: 0px !important;
}

.menu-items__container {
  display: flex;
  flex-wrap: wrap;
}

.menu-items__container-featured{
  display:flex;
  justify-content:flex-start;
}

.menu-items__container-featured-mobile{
  display:flex;
  justify-content:space-between;
}

.menu-select-container{
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.menu-select{
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  font-weight: bold;
}

@media only screen and (max-width: 3000px){
  .menu-select-container{
    width: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 15px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  }

  .menu-select{
    width: 100%;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: bold;
  }
}

.nft-container-irazu{
  height: 300px;
  background: black;
  border-radius: 6px;
  width:100%;
  max-width:1200px;
  display:flex;
  align-items:flex-start;
  justify-content:flex-start;
  overflow:hidden;
}

.nft-irazu-right{
  box-sizing:border-box;
  height:100%;
  width:calc(100% - 232px);
  background-image: url('https://cdn.hngr.co/grid100.png');
  padding:32px;
  display:flex;
  align-items:center;
}

.nft-irazu-right-img-box{
  box-sizing:border-box;
  width: 240px;
  height: 240px;
  left: 264px;
  top: 30px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  display:flex;
  align-items:center;
  justify-content:center;
  background:black;
}

.nft-irazu-right-img-box img{
  height:180px;
}



.nft-irazu-right-content{
  display:flex;
  flex-direction:column;
  margin-left:32px;
  height:100%;
  width:calc(100% - 280px);
  box-sizing:border-box;
  justify-content:space-between;
}

.nft-irazu-right-content-title{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: #FFFFFF;
  margin-top:20px;
  margin-bottom:16px;
}

.nft-irazu-edition-box{
  background: #141414;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  height:59px;
  padding:8px;
  width:160px;
  justify-self:flex-end;
  display:flex;
  justify-content:space-between;
  box-sizing:border-box;
  flex-direction:column
}

.nft-irazu-green-button{
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  width:fit-content;
  padding: 0px 24px;
  height: 52px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}


.nft-irazu-green-button:hover{
  background: #5AFF90;
  box-shadow: 2px 4px 0px #079E38;
}

.nft-irazu-edition-box-title{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}

.nft-irazu-edition-box-subtitle{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #BBBBBB;
}

.nft-container{
  height: 340px;
  background: #141414;
  border-radius: 10px;
  width:100%;
  max-width:1200px;
  display:flex;
  align-items:flex-start;
  justify-content:flex-start;
}

.nft-outer-container{
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%;
  padding-top:50px;
}

.nft-left{
  border-radius:10px;
  height:100%;
  background-color:black;
  width:280px;
}

.nft-image{
  height:280px;
  width:280px;
  border-radius:10px;
}

.nft-left-bottom{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  width:100%;
  padding-left:20px;
  padding-right:20px;
  box-sizing:border-box;
}

.nft-left-nft-supper-club{
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
  color:white;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
}

.nft-left-nft-name{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #71E4FE;
}

.nft-left-bottom-left{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
}

.nft-right{
  width:calc(100% - 280px);
  background: #141414;
  border-radius: 10px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
  box-sizing:border-box;
  height:100%;
  padding-left:30px;
  padding-right:30px;
}

.nft-right-supper-club{
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  color:white;
  margin-left:10px;
}

.nft-right-top-row{
  display:flex;
  align-items:center;
}

.nft-right-wallet-address{
  margin-right:15px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #EBC35C;
  background: #4A4026;
  border-radius: 5px;
  height:29px;
  margin-left:17px;
  padding-right:8px;
  padding-left:8px;
}

.nft-right-welcome-back{
  color:white;
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
}

.nft-earn-rewards{
  color: #71E4FE;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.nft-button-row{
  display:flex;
  margin-top:15px;
}

.nft-claim-rewards{
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:24px;
  padding-right:24px;
  height: 37px;
  background: #71E4FE;
  border-radius: 31px;
  box-sizing:border-box;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color:#141414;
}

.nft-learn-more{
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:24px;
  padding-right:24px;
  height: 37px;
  color: #71E4FE;
  background: #141414;
  border-radius: 31px;
  box-sizing:border-box;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  background-color:#141414;
  margin-left:15px;
}
.nft-detected{
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #00ED34;
  /* identical to box height */
  background: #165F26;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  height:29px;
  padding-left:8px;
  padding-right:8px;
  width: fit-content;

}
.nft-irazu-right-blurb{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.nft-tabs{
  height:100%;
  box-sizing:border-box;
  width:232px;
  display:flex;
  flex-direction:column;
}

.nft-tab{
  width:100%;
  height:100px;
  box-sizing:border-box;
  display:flex;
  align-items:center;
  justify-content:flex-start;
  padding-left:16px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #FFFFFF;
  cursor:pointer;
}

.nft-tab-selected{
  background: #39F376;
  color:black;
}

.cart-nft-irazu{
  background: #141414;
  border-radius: 6px; 
  width:100%;
  padding:12px;
  box-sizing:border-box;
}

.cart-nft-irazu-header{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FCFCFC;
}

.cart-nft-irazu-promo-applied{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #39F376;
}

.cart-nft-irazu-bottom{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FCFCFC;
}

.nft-irazu-button-claimed{
  background: #BBBBBB;
  box-shadow: 2px 4px 0px #6F6F6F;
  pointer-events:none;
}

.nft-irazu-help-box{
  padding:16px;
  box-sizing:border-box;
  height:236px;
  background: #141414;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  width:352px;
}

.nft-irazu-help-box-title{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #FFFFFF;
  margin-bottom:8px;
}


.nft-irazu-help-box-blurb{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #BBBBBB;
}

.nft-irazu-help-box-green-button{
  width: 320px;
  height: 52px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  cursor:pointer;
  text-decoration:none;
  display:flex;
  align-items:center;
  justify-content:center;
}


.nft-irazu-help-box-white-button:hover{
  color: #39F376;
  border-color: #39F376;
}

.nft-irazu-help-box-white-button{
  width: 320px;
  height: 52px;
  background:black; 
  color:white;
  border-radius: 30px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  cursor:pointer;
  text-decoration:none;
  display:flex;
  align-items:center;
  justify-content:center;
  border: 2px solid #FFFFFF;
}



.nft-irazu-help-box-green-button:hover{
  background: #5AFF90;
  box-shadow: 2px 4px 0px #079E38;
}

.nft-irazu-help-box-discord-button{
  height: 52px;
  background: #000000;
  border: 2px solid #39F376;
  border-radius: 30px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #39F376;
  width:320px;
}


.nft-irazu-help-box-discord-button:hover{
  border-color:white;
  color:white;
}

.nft-irazu-help-box-discord-button:hover *{
  fill:white;
}

.redeemable-june-1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  height: 56px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;


  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;

}

.crypto-banner{
  max-width:1200px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100%;
  background-color:black;
  height: 80px;
  background-image: url('https://cdn.hngr.co/grid100.png');
  border-radius: 10px;
  margin-top:60px;
  padding:8px 16px;
  box-sizing:border-box;
}

.crypto-banner-wrapper{
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%;
}

.unlock-exclusive{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  /* identical to box height */
  color: #39F376;
  margin-left:22px;

}


.looking-for-nft{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  /* identical to box height */
  color: #39F376;
  display:flex;
  align-items:center;
}

.looking-for-nft img{
  margin-left:10px;
  width:60px;
  height:60px;
}
