.trackerContentContainer {
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  margin: 5vh auto;
}

.trackerOrderNumber {
  display: flex;
  font-family: 'Nunito Sans';
  font-weight: 50;
  padding-top: 16px;
}

.trackerStatus {
  font-family: 'Nunito Sans';
  font-weight: 900;
  font-size: 30px;
}

.trackerContact {
  font-weight: 300;
}

.trackerHash {
  font-weight: bold;
}

.trackerStatusTextWrapper {
  text-align: left;
  width: 100%;
  padding : 0 16px;
}


.trackerToggleBoxWrapper {
  border-radius: 8px;
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 48px 16px 16px 16px;
  padding: 32px 0 16px 0;
}

@media only screen and (max-width: 800px){
  .trackerToggleBoxWrapper{
    margin: 48px 0 16px 0;
  }
}

.trackerToggle {
  width: 250px;
  border: 1px solid #eee;
  border-radius: 6px;
  height: 45px;
  margin: auto;
  overflow: hidden;
  position: absolute;
  top: -24px;
}

.trackerButtonBlue {
  background: #6BADF4;
  color: #fff;
  font-weight: 900;
}

.trackerButtonWhite {
  background: #fff;
  color: #222;
  font-weight: 200;
}

.trackerStatusButton {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  border: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  cursor: pointer;
}

.trackerReceipt {
  max-width: 400px;
  max-height: 90%;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}


.trackerToggleContent {
  width: 100%;
  height: 90%;
}

.trackerSplitter {
  width: 100%;
  height: 1px;
  background-color: #dedede;
  position: absolute;
  top: 50%;
}

.trackerLine {
  height: 45%;
  position: relative;
  width: 80%;
  margin: 32px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.trackerTimeline {
  width: 90%;
  margin: 0 5%;
  height: 3px;
  background-color: #7f7f7f;
  position: absolute;
  top: 20px;
}

.trackerTimelineSegment {
  height: 3px;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.trackerBubbleContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.trackerBubble {
  position: relative;
  height: 40px;
  width: 40px;
  border: 3px solid #aeaeae;
  border-radius: 100%;
  background-color: white;
}

.trackerSubBubble {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  left: 5px;
  overflow: hidden;
}

.trackerBubbleFill {
  height: 30px;
  width: 30px;
  animation: Filler 1s;
  -webkit-animation: Filler 1s;
  -moz-animation: Filler 1s;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@keyframes Filler {
  0% {
    width: 0;
  }
}

@-webkit-keyframes Filler {

  0% {
    width: 0;
  }
}

@-moz-keyframes Filler {
  0% {
    width: 0;
  }
}

.trackerBubbleCaption {
  max-width: 90px;
  padding-top: 10px;
  font-family: 'Nunito Sans';
  font-weight: 200;
  color: #707070;
  font-size: 13px;
  text-transform: uppercase;
}

.trackerAddress {
  position: relative;
  width: 100%;
  text-align: center;
}

.trackerAddressTitle {
  font-family: 'Nunito Sans';
  font-weight: 900;
  font-size: 18px;
  color: #707070;
}

@media only screen and (max-width: 320px) {
  .trackerAddressTitle {
    font-size: 18px;
  }
}


#addressLink {
  color: blue;
  text-decoration: underline;
}

.trackerAddressLine {
  font-weight: 500;
  margin-top: 12px;
  font-size: 14px;
  padding-bottom: 8px;
}

.trackerAddressLine2 {
  font-weight: 100;
  font-style: oblique;
  margin-top: 12px;
  font-size: 18px;
}

.trackerReorderButton {
  margin: 8px 0px;
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, .3);
}

.trackerReorderButton:hover {
  margin: 8px 2px;
  box-shadow: 5px 5px 12px -2px rgba(0, 0, 0, .4);
}