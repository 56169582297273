.MegaphoneWrapper{
  display:inline-block;
  position: relative;
  text-align:center;
  padding-top: 16px;
  transition: all .2s;
}

.MegaphoneWrapper:empty{
  padding: 0;
}

.MegaphoneContainer{
  position: relative;
  display: inline-block;
  text-align: center;
  width: calc(100% - 32px);
  box-sizing: border-box;
  max-width: 360px;
  padding: 16px 8px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px rgba(107, 86, 86, 0.2);
  font-size: 16px;
  font-weight: 900;
  margin: 8px;
  vertical-align: middle;
}

.MegaphoneClose{
  position: absolute;
  right: 12px;
  top: 8px;
  font-weight: 700;
  color: #ccc;
  font-size: 18px;
  cursor: pointer;
}

.MegaphoneImg{
  width: 96px;
}

.MegaphoneButton{
  display: inline-block;
  margin: 8px 16px 0px 16px !important;
  font-size: 12px !important;
  padding: 8px 16px !important;
}

@keyframes grow-in {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 48vh;
    padding: 0px 0px 24px 0px;
  }
}

@keyframes shrink-out {
  from {
    max-height: 48vh;
    opacity: 1;
    padding: 0px 0px 24px 0px;
  }
  to {
    max-height: 0;
    opacity: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 800px) {
    .MegaphoneWrapper{
      width: 100%;
    }
    .MegaphoneContainer{
      font-size: 16px;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
    }
    
    100%{
        opacity: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        display: none;
    }

}

@keyframes collapse {
    from {
        height: 100%;
    }
    to{
        height: 0px;
    }
}
