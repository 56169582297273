.anonPasswordReset {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.anonPasswordReset h3 {
  font-size: 24px;
}

.anonPasswordReset .label {
  font-weight: bold;
  margin-top: 10px;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  text-align: left;
  margin-bottom: 5px;
}

.anonPasswordReset .words {
  margin-top: 10px;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  text-align: center;
  margin-bottom: 5px;
}

.anonPasswordReset button {
  flex: 'flex-grow';
  color: white;
  border-radius: 4px;
  padding: 14px 30px;
  width: 90%;
  font-weight: 900;
  font-size: 18px;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  border: 0;
}

.anonPasswordReset input {
  flex: 'flex-grow';
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  height: 53px;
  padding: 14px 10px; 
  font-size: 18px;
  width: 90%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
