.RestaurantHeader{
  min-height: 60px;
  box-shadow: 0px 8px 16px -4px rgba(0,0,0,.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 997;
}

.RestaurantHeaderMenu{
  position: absolute;
  right: 0;
  top: 0;
  height: 28px;
  padding: 16px 24px;
  cursor: pointer;
}

.RestaurantHeaderLogo{
  height: 48px;
  padding: 4px;
  transition: all .2s;
}

.RestaurantHeaderLogo:hover{
  height: 52px;
  padding: 2px;
  cursor: pointer;
}

.RestaurantHeaderProfile{
  position: absolute;
  right: 60px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  vertical-align: middle;
  cursor: pointer;
  max-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}