.Account{
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

/* .user-input-wrp {
	position: relative;
	width: 50%;
}
.user-input-wrp .inputText{
	width: 100%;
	outline: none;
	border:none;
	border-bottom: 1px solid #ccc;
 	box-shadow: none !important;
}
.user-input-wrp .inputText:focus{
	border-color: #4284f4;
	border-width: medium medium 2px;
}
.user-input-wrp .floating-label {
	position: absolute;
	pointer-events: none;
	top: 18px;
	left: 10px;
	transition: 0.2s ease all;
}
.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label{
	top: 0px;
	left: 10px;
	font-size: 13px;
	opacity: 1;
} */