@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900");

h1 {
  font-weight: 900;
}

h3 {
  font-weight: 900;
}

button {
  font-family: "Nunito Sans", sans-serif;
}

.ModalBackground {
  margin: auto;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 48px);
  padding-bottom: 48px;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: hidden;
}

.ModalContainer {
  position: relative;
  height: auto;
  max-height: calc(92vh - 72px);
  width: calc(95vw - 40px);
  max-width: 800px;
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  animation: drop-in ease 0.5s forwards;
  opacity: 0;
  overflow-y: auto;
}

.ModalContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}

.AppBody {
  width: calc(100% - 320px);
  position: relative;
  display: inline-block;
  padding: 60px 0px;
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 14px;
  }
  .AppBody {
    width: 100%;
  }
}

.AppBody-footer{
  padding-bottom:0px !important;
}

#Intercom {
  border-radius: 50%;
  padding: 8px;
  width: 48px;
  height: 48px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: all 0.2s;
  cursor: pointer;
  z-index: 997;
}

#Intercom:hover {
  animation: twist-hover 0.5s forwards ease;
}

@keyframes twist-hover {
  0% {
    transform: scale(1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  }
  40% {
    transform: scale(1.1) rotate(20deg);
    box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.3);
  }
  60% {
    transform: scale(1.15) rotate(-10deg);
    box-shadow: 0px 8px 16px 2px rgba(0, 0, 0, 0.4);
  }
  85% {
    transform: scale(1.12) rotate(5deg);
    box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.3);
  }
  100% {
    transform: scale(1.1) rotate(0deg);
    box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.3);
  }
}

@keyframes drop-in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
    margin-top: -2.5vh;
  }
  30% {
    opacity: 0;
    transform: translateY(-20%);
    /* margin-top: 0vh; */
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    margin-top: 0vh;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.colored {
  background-color: green;
}
d .logoFormatting {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}

body {
  margin: 0;
  /* background-color: #f9f9f9; */
  height: 100%;
}

li {
  list-style-type: none;
  padding-bottom: 20px;
}

ul {
  padding: 0;
}

.rectangle {
  width: 100%;
  height: 2em;
}

a {
  text-decoration: none;
  color: inherit;
}

*:focus {
  outline: none;
}

::placeholder {
  font-weight: 300;
  color: "#555555";
}

#scrolling {
  display: "inline-block";
  white-space: "nowrap";
  overflow-x: "scroll";
  width: "100%";
}

#scrolling::-webkit-scrollbar {
  display: none;
}

.facebook-login-button {
  background-color: "blue";
  color: "white";
  text-align: "center";
  font-size: "11pt";
  border: "0";
  display: "inline-block";
  padding: "15px 20px";
  border-radius: "70px";
  vertical-align: "200%";
  width: "160px";
  margin-left: "20px";
}

.horizontalScroll {
  /* position: fixed; */
  background-color: whitesmoke;
  top: 60px;
  left: 0px;
  padding: 12px 5px;
  white-space: nowrap;
  overflow-x: scroll;
  width: 100%;
  /* position: relative; */
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}

.horizontalScroll::-webkit-scrollbar {
  display: none;
}

/* FONT FAMILIES & TEXT FORMATTING */

.mainHeader {
  display: block;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  font-size: 32pt;
  color: black;
  padding-top: 20px;
  padding-bottom: 10px;
}

.pageHeader {
  font-size: 24pt;
  font-weight: 700;
  padding: 10px 10px 20px 10px;
  color: black;
  display: block;
}

.subHeaderBold {
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
}

.subHeaderMedium {
  display: inline-block;
  font-size: 22px;
  font-weight: 300px;
}

.elementHeaderBold {
  font-size: 14pt;
  font-weight: 600;
}

.elementHeaderMedium {
  font-size: 14pt;
  font-weight: 400;
}

.elementHeaderLight {
  font-size: 14pt;
  font-weight: 200;
}

.priceMedium {
  font-size: 12pt;
  font-weight: 400;
  color: green;
}

.priceSmall {
  font-size: 16px;
  font-weight: 400;
  color: green;
}

.description {
  font-size: 11pt;
  font-weight: 300;
}

.input {
  box-sizing: border-box;

  font-size: 12pt;
  font-weight: 500;
  color: #222222;
}

.cardHeader {
  display: inline-block;
  padding: 0px 10px 10px 10px;
  font-weight: 600;
  font-size: 24pt;
}

/* FORMATTING DEFAULTS */

.leftRail {
  position: fixed;
  background-color: white;
  left: 0;
  top: 60px;
  height: calc(100% - 60px);
  width: 15%;
  min-width: 240px;
  z-index: 999;
  padding-left: 10px;
  padding-right: 10px;

  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.card {
  background-color: white;
  margin: 5px;
  box-shadow: 1px 1px 5px 0.5px lightgray;
  border: 0px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.hngrButton {
  border: 0px;
  box-shadow: 1px 5px 20px -2px #ccc;
  border-radius: 6px;
  color: white;
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 12px 36px;
  text-align: center;
  background: #ccc;
  margin: 5px;
  transition: all 0.1s;
  text-transform: uppercase;
  cursor: pointer;
}

.hngrButton:hover {
  transfrom: scale(1.01);
}

.BackToMenuButton {
  position: fixed;
  top: 80px;
  right: 16px;
  font-size: 12px;
  padding: 8px 16px;
  letter-spacing: 0;
  text-transform: none;
  z-index: 10;
}

.card-expanded {
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
  background-color: white;
  transition: max-height 1000ms 0ms, opacity 200ms 200ms;
  z-index: 900;
}

.card-hidden {
  transition: max-height 200ms 0ms, opacity 200ms 0ms;
  opacity: 0;
  max-height: 0px;
  z-index: 0;
  pointer-events: none;
  cursor: none;
}

.card-hidden > div {
  display: none;
}

.inheritHidden {
  max-height: inherit;
  opacity: inherit;
  z-index: inherit;
  padding: inherit;
  margin: inherit;
  cursor: inherit;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.center {
  text-align: center;
}

.inline {
  display: inline-block;
}

@keyframes animateCategories {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@font-face {
  font-family:Haettenschweiler;
  src: url("/public/fonts/Haettenschweiler-Regular.ttf") format("truetype");
}

