.errorContainer {
  background-color: hsl(0, 100, 94);
  color: hsl(0, 100%, 42%);
  padding: 12px;
  border-radius: 6px;
  margin: 12px 0;
  display: flex;
  align-items: center;
}

.errorContainerNoBorder {
  @extend .errorContainer;
  margin: 0;
  border-radius: 0px;
}

.error {
  margin: 0 8px;
}
