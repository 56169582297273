.connect-wallet-button{
  background-color:white;
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%;
  color:black;
  padding-left: 16px;
  padding-right: 16px;
  border-radius:6px;
  height:57px;
  box-sizing:border-box;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.solana-unconnected .wallet-adapter-button{
  padding:0px !important;
  height:57px !important;
  background-color:white !important;
  border: 4px solid #8263ED !important;
  height:65px !important;
  border-radius:6px;
  position:relative;
  top:-15px;
}


.header-wallet-button-container .wallet-adapter-button{
  padding:0px !important;
  height:40px !important;
  background-color:white !important;
  border: 0px solid #8263ED !important;
  border-radius:6px;
  position:relative;
  box-sizing:border-box;
}

.solana-unconnected .wallet-adapter-button i, .solana-unconnected-header .wallet-adapter-button i{
  display:none;
}

.wallet-change-button-container .wallet-adapter-button i{
  display:none;
}


.solana-connected-header .wallet-adapter-button i{
  display:none;
}

.wallet-button-address{
  color:black;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.wallet-button-change{
  width: 49px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #551BF7;
  width: 65px;
  height: 27px;
  top: 0px;
  border: 1px solid #551BF7;
  box-sizing: border-box;
  border-radius: 8px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.wallet-change-button-container .wallet-adapter-button-trigger{
  padding:0px !important;
}

.wallet-change-button-container .wallet-adapter-button-trigger:hover{
  background-color:transparent !important;
}

.solana-connected .wallet-adapter-button-trigger, .solana-connected-header .wallet-adapter-button-trigger{
  background-color:transparent !important;
}

.header-wallet-button-container .connect-wallet-button{
  height:40px !important;
}

.crypto-banner .wallet-adapter-button-trigger{
  padding:0px !important;
}


.crypto-banner .wallet-adapter-button i{
  display:none;
}

.crypto-banner .sol-img{
  display:none;
}

.crypto-banner .connect-wallet-button{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  height: 36px;
  background: #000000;
  border: 2px solid #39F376;
  border-radius: 30px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #39F376;
  background-color:black;
}

.crypto-banner .wallet-adapter-button-trigger{
  background-color:black;
}


.crypto-banner .connect-wallet-button:hover{
  color:white;
  border-color:white;
}
