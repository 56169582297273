.thankyou-container{
  padding-left:15px;
  padding-right:15px;
  padding-top:15px;
}


@media only screen and (min-width:920px) {
  .thankyou-container{
    width:430px;
    padding-left:15px;
    padding-right:15px;
    padding-top:25px;
  }

}

.left-right{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
}

.thankyou-text-large{
  font-size:18px;
  font-weight:900;
}

.thankyou-text-small{
  font-size:14px;
}

.thankyou-order-details{
  padding-top:14px;
  padding-bottom:7px;
}

.thankyou-section{
  padding-top:20px;
  padding-bottom:20px;
}

.grey-bottom-border{
  border-bottom:3px solid #f3f3f3;
}

.flex-direction-row-start{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
}

.extra-bold{
  font-weight:900;
}

.thankyou-call-button{
  width:90%;
  box-sizing:border-box;
  padding:6px;
  border-radius:5px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.thankyou-supper-club-button{
  width:70%;
  box-sizing:border-box;
  padding:6px;
  border-radius:5px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.thankyou-please-continue-with-social-media{
  width:65%
}

.thankyou-social-media-container{
  box-sizing:border-box;
  display:flex;
  width:32%;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.thankyou-social-media-container-dekstop{
  padding-top:15px;
}


.thankyou-social-media-container-dekstop svg{
  margin-right:20px;
}

.thankyou-order-status{
  font-weight:900;
  font-size:22px;
}

.thankyou-status-bar-container{
  width:100%;
  display:flex;
  flex-direction:row;
}

.thankyou-grey-bar{
  background-color: #f3f3f3;
  height:5px;
}

.thankyou-color-bar{
  height:5px;
}

.flex-start{
  flex-direction:row;
  display:flex;
  align-items:center;
  justify-content:flex-start;
}

.thankyou-status-list{
  padding-top:10px;
}

.thanky-you-status-list-right{
  padding-left:10px;
}

.thankyou-status-list-status{
  padding-top:5px;
  padding-bottom:5px;
}

.thankyou-status-checkmark{
  position:relative;
  top:2px;
}

.thankyou-grey{
  color:grey;
}

.thankyou-grey svg{
  fill:grey;
}

.thankyou-ready-at{
  margin-top:15px;
  margin-bottom:15px;
}

.thankyou-receipt-container{
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;
}

.thankyou-receipt{
  width:100%;
}

.thankyou-orderReceiptContainer{
  position: relative;
  display: inline-block;
  max-height: 85%;
  margin-top: 5%;
  width: 100%;
  background: #fff;
  overflow:hidden;
  overflow-y: auto;
  border:4px solid #f3f3f3;
}

.thankyou-orderReceiptFrame{
  width:100%;
  position: relative;
  top:0;
  left:0;
  border:0;
  overflow:hidden;
}

.thankyou-cart-footer{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.thankyou-cart-footer-link{
  color:blue;
  text-decoration:underline;
  padding-top:15px;
} 

.thankyou-dekstop-receipt{
  width:85%;
  position:relative;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
}

.thankyou-orderReceiptContainer-desktop{
  background-color: #f3f3f3;
}

.map-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  width:16px;
  height:16x;
}

.thankyou-map-icon-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  width:27px;
  height:27px;
  border-radius:50px;
  cursor:pointer;
}

.thankyou-doordash{
  padding-left:25px;
  font-size:12px;
}

.thankyou-car{
  display:inline-block;
  position:relative;
  top:3px;
  padding-right:4px;
}

.thankyou-car-line svg{
  position:relative;
  top:3px;
}

.thankyou-cart-mobile{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:top;
}

.thankyou-bitcoin-pizza-title{
  font-size:24px;
  font-weight:700;
}

.thank-you-please-complete{
  font-size:16px;
  font-weight:400;
  margin-top:15px;
}

.thankyou-yellow-blurb{
  padding:8px;
  background: #FFFAF4;
  border: 1px solid #FC9601;
  box-sizing: border-box;
  border-radius: 4px;
}

.thankyou-click-pay-blurb{
  display:flex;
  flex-direction:column;
  align-items:center;
  background: #F4F4F4;
  border-radius: 4px;
  padding:16px;
  margin-top:15px;
}

.thankyou-click-pay-price{
  font-size:24px;
  font-weight:700;
}

.thankyou-click-contine{
  font-size:16px;
  color: #9B9B9B;
}

.pay-with-bitcoin-button{
  height:56px;
  width:100%;
  background-color:#171717;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:row;
  border-radius:4px;
  margin-top:15px;
}

.pay-with-bitcoin-button-text{
  color:#FAFAFA;
  margin-left:6px;
  font-size:16px;
  font-weight:700;
}

.pay-with-bitcoin-redirect-warning{
  font-size:13px;
  font-weight:400;
  text-align:center;
  margin-top:10px;
}

.bitcoin-is-processing-text{
  font-size:16px;
  font-weight:400;
  margin-top:15px;
}

.thankyou-processing-blurb{
  background: #FFFAF4;
  border: 1px solid #FC9601;
  box-sizing: border-box;
  border-radius: 4px;
  padding:16px;
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:15px;
}

.thankyou-bitcoin-paid{
  background: #ECFFF1;
  border: 1px solid #28A745;
  box-sizing: border-box;
  border-radius: 4px;
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:16px;
}

.thankyou-bitcoin-txn-complete{
  color:#28A745;
  font-size:16px;
  font-weight:400;
}

.thankyou-bitcoin-txn-complete{
  text-align:center;
}

.thankyou-bitcoin-image-container{
  margin-top:15px;
  margin-bottom:15px;
}

.thankyou-hour-glass-container{
  width:131px;
  height:131px;
  border-radius:131px;
  background-color:#FC9601;
  display:flex;
  align-items:center;
  justify-content:center;
}


.thankyou-hour-glass-container img{
  height: 80px;
}
