.toppings {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__button {
    margin: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    // animation: bounce-in 200ms;

    &:hover {
      cursor: pointer;
    }
  }
}

.selected {
  animation: bounce-pizza 100ms;
}

@keyframes bounce-pizza {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
