@import "../../css/foodcoinStandards.scss";
.header {
  position: fixed;
  top: calc(var(--supperclub-back-height) + 60px + var(--inset-top));
  left: 0;
  background-color: white;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 80%;
  width: 100%;
  padding: 12px 16px 8px 16px;
  box-shadow: 0 1px 9px 0 rgba(193, 193, 193, 0.5);
  z-index: 996;

  &:hover {
    cursor: pointer;
  }
}
.selected {
  @extend %headers;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0px;
  height: 48px;

  &:first-child div {
    display: inline-block;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 56px);
  }
}

.filler {
  &::after {
    content: "";
    width: 100%;
    height: 40px;
    background: linear-gradient(
      to bottom,
      rgba(254, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.74) 50%,
      white 100%
    );
    position: absolute;
    bottom: 0;
  }
}

.foodmoji {
  height: 32px;
  width: 32px;

  margin: 0 8px;
}

.category {
  padding: 4px 0;
  margin: 4px 0;
  opacity: 1;
  border-radius: 6px;
  transition: color 100ms ease-out;
  &:hover {
    // color: #495057;

    cursor: pointer;
  }

  &__selected {
    @extend .category;
    color: $fc-black;
  }
}
.list {
  display: flex;

  flex-direction: column;
  @extend %headers;
  color: #858e96;
  -webkit-overflow-scrolling: touch;
}

.dropIcon {
  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  display: flex;
  align-items: center;
}

.dropIconExpanded {
  @extend .dropIcon;
  transform: rotate(180deg);
}

.BlackedOutMobileCategory{
  display:none;
}

.GreyedOutMobileCategory{
  text-decoration:line-through;
}
