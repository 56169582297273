@import "../../css/foodcoinStandards.scss";
html {
  font-family: "Nunito Sans", sans-serif;
}


.name {
  @extend %headers;
  font-size: 36px;
  margin-bottom: 16px;
}

.description {
  color: #000;
  font-size: 14px;
}

.modifiers {
  background: blue;
}

.modifier {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options {
}

.container {
  position: relative;
  // margin: 32px;
  width: 750px;
  // max-width: 840px;
}

.addToCart {
  z-index: 999;
  display: flex;
  width: 100%;
  bottom: 0;
  min-height: 60px;
  justify-content: center;

  background-color: white;
  flex-direction: column;
  padding: 0 0 16px 0;

  border-top: 2px solid #d8d8d8;
  &::before {
    content: "";
    width: 100%;
    height: 40px;
    background: linear-gradient(
      to bottom,
      rgba(254, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.74) 50%,
      white 100%
    );

    position: absolute;
    top: -42px;
  }

  & > button {
    touch-action: manipulation;
  }
}
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  //overflow-y: scroll;
  padding: 32px;
  -webkit-overflow-scrolling: touch;
}

.add {
  font-size: 12px;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  padding: 8px 24px;
  border: none;
  touch-action: manipulation;

  @extend %hover;
}

@media only screen and (max-width: 1000px) {
  .container {
    position: relative;
    max-width: none;
    min-width: 0;
    width: 90%;
    // padding-bottom: 75px;
  }

  .addToCart {
    position: fixed;
    bottom: 0;
  }

  .wrapper {
    padding: 36px 16px 100px 16px;
    width: 90%;
  }
}

.close {
  background: none;
  border: none;
  margin-left: 32px;
  position: relative;
  top: -25px;
  padding: 0;
  width: 24px;

  &:hover {
    cursor: pointer;
  }
}

.requiredContainer {
  border-radius: 24px;
  background-color: #E55858;
  display: flex;
  padding: 3px 9px;
  margin-left: 12px;
  justify-content: center;
  align-items: center;
}

.requiredText {
  font-size: 14px;
  font-weight: 800;
  color: white;
}

.selectedContainer {
  border-radius: 24px;
  background-color: #34A853;
  display: flex;
  padding: 3px 9px;
  margin-left: 12px;
  justify-content: center;
  align-items: center;
}

.modifierContainer {
  padding: 8px;
  
  &:not(:first-child) {
    margin-top: 16px;
  }
}

.requiredModifierContainer {
  @extend .modifierContainer;
  padding: 16px 8px;
  background-color: #F5F5F5;
}

.optional {
  color: #AEAEAE;
  font-size: 14px;
  font-weight: 700;
}

.upcharge {
  margin: -8px 0 0 12px; // 12 - 4 = 8, 12px for input component
  font-size: 12px;
  color: #AEAEAE;
}
