@import "../../css/foodcoinStandards.scss";

%change-button {
  @extend %text-rules;

  background: #eaecef;
  font-weight: 500;
  border: none;
  font-size: 12px;
  margin: 16px 0 16px 0;
  padding: 8px 16px;
  border-radius: 30px;
  color: $fc-black;

  &:hover {
    cursor: pointer;
  }
}

.change {
  @extend %change-button;
  padding: 8px 24px;
}

.paymentMethod {
  width: 100%;
}

.paymentContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
