.brand-page__loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-page__results-container {
  max-width: 1440px;
  margin: 46px auto;
}

.brand-page__location-container {
  display: flex;
  flex-wrap: wrap;
}

.brand-page-header__container {
  padding: 32px;
}

.brand-page-header__container--limit {
  max-width: 1440px;
  margin: 0 auto;
}

.brand-page__cur-loc {
  width: 20px;
  padding: 0;
}

.brand-page__auto-complete-container {
  display: flex;
  margin-top: 48px;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.brand-page__auto-complete-header {
  color: white;
  font-weight: 900;
  font-size: 32px;
  margin-right: 16px;
}

.brand-page__address {
  width: 100%;
  max-width: 800px;
  animation: drop-in ease 500ms forwards;
  z-index: 2;
  display: flex;
  align-items: center;
}

.brand-page__current-location {
  padding: 14px 18px;
  font-size: 16px;
  max-width: 256px;
  margin: 8px;
  flex-basis: 35%;
}

.brand-page-header__logo {
  width: 148px;
}

.brand-page-header__brand-name {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 1440px) {
  .brand-page__results-container {
    margin: 32px;
  }
}

@media only screen and (max-width: 400px) {
  .brand-page__location-container {
    margin: 16px auto;
  }

  .brand-page__current-location {
    flex-basis: inherit;
  }
}
