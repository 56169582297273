.legal-footer{
  width:100%;
  background-color:#f9f9f9;
  padding:20px 10px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
}

.legal-footer-inner-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
}

.legal-footer-divider{
  width:100%;
  height:1px;
  background-color:#0C1843;
  margin-top:10px;
  margin-bottom:10px;
}

.legal-footer-links-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.legal-footer a {
  padding:5px 10px;
  font-weight:400;
  font-size:14px;
  color:black;
}

.legal-footer-top-row{
  font-weight:800;
  font-size:12px;
  color:#0C1843;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.legal-footer-top-row svg{
  margin-left:5px;
  height:19px;
}

@media only screen and (max-width: 800px) {
  .legal-footer-links-container{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
  }

  .legal-footer-menu{
    padding-bottom:100px !important;
  }

  .legal-footer-checkout{
    padding-bottom:calc(70px + var(--inset-bottom)) !important;
  }

}

