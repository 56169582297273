@import "../../css/foodcoinStandards.scss";
.image {
  width: 128px;
}

.container {
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.header {
  @extend %headers;
  font-size: 32px;
  font-weight: 600;
  color: $fc-black;
  margin: 8px;
  text-align: center;
}

.subheader {
  font-size: 16px;
  color: #858e96;
}

.search {
  border: 0;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  display: inline-block;
  max-width: 200px;
  &::placeholder {
    color: #666;
    opacity: .6;
  }
  transition: all .2s;
}

.search:focus{

  border-bottom: 3px solid #4284f4;
}
