@import "../../css/foodcoinStandards.scss";

.title {
  @extend %headers;

  // margin: 0px auto 48px auto;
  // padding-top: 56px;
  padding: 72px 0 0 0;
  font-size: 36px;

  display: flex;
  justify-content: flex-start;

  // &:first-child div {
  //   // width: 100%;
  //   // text-align: center;
  //   // padding: 4px;
  //   // // background: rgb(231, 182, 182);
  //   // border-radius: 6px;
  // }
}

.container {
  margin: auto 64px;
  display: flex;
  flex-direction: column;
  // border-bottom: 2px solid #dee2e6;
}

.clear {
  padding: 8px 24px;
  border: none;
  border-radius: 6px;
  background-color: #cfcfee;
  color: #5454c4;
  font-weight: 700;
  font-size: 12px;
  margin: auto;
  margin-top: 32px;

  &:hover {
    @extend %hover;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1000px) {
  .container {
    margin: auto 32px;
  }
}

.menuContainer {
  background-color: "white" !important;
  margin: 0 64px;
  min-height: 100%;
  padding-bottom: 500px;
  -webkit-overflow-scrolling: touch;

  transition: background-color 100ms linear;
}

@media only screen and (max-width: 1000px) {
  .menuContainer {
    margin: 0;
  }

  .container {
    margin: 0;
  }

  .title {
    margin: 0 32px;
  }
}

.horizontalScroll {
  background-color: white;
  box-shadow: 0 1px 9px 0 rgba(193, 193, 193, 0.5);

  padding: 12px 5px;

  width: 100%;
  position: fixed;
  height: 50px;
  zindex: 1;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
