html, #root, body{
  height:100%;
  /*
  width:100%;
  min-width: fit-content;
  min-height: fit-content;
  */
}

.bitcoin-loading-background{
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: #343A40;
}

.all-locations-page-search-desktop-container{
  display:flex;
  width:100%;
  background-color: #343A40;
  min-height: 100%;
}

.locations-page-search-desktop-container{
  width:100%;
  display:flex;
  flex-direction:row;
}

.all-locations-page-mobile-container{
  width:100%;
  display: flex;
  min-height:100%;
  background-color: #343A40;
} 

.locations-page-mobile-container{
  width:100%;
  display:flex;
  flex-direction:column;
  /*min-height:100vh;*/
  min-height:100%;
  background: #343A40;
  position:relative;
  box-sizing:border-box;
  padding-bottom:40px;
} 


.locations-page-inner-container{
  width:100%;
  height: calc(100% - 76px);
  top:476px;
  display:flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: center;
}


.locations-page-inner-inner-container{
  width:62%;
  /*position:relative;
  top:76px;
  padding-top: 16px;*/
  padding-top:92px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.locations-page-inner-container-mobile{
  width:100%;
  height: calc(100% - 55px);
  top: 55px;
  display:flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;;
}


.locations-page-inner-inner-container-mobile{
  width:100%;
  padding-top:55px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.locations-page-title{
  font-family: Connection Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 31.25px;
  line-height: 37px;
  color: #FC9601;
  margin-bottom: 16px;
}

.locations-page-button-dropdown-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.locations-page-button-dropdown-container-mobile{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.locations-page-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  width: 40%;
  height: 40px;
  background: #409BFF;
  border-radius: 20px;
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FAFAFA;
}

.locations-page-full-dropdown-container{
  width: 45%;
}

.locations-page-dropdown{
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #FC9601;

  height: 30px;
  border-bottom: 1px solid #FC9601;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.locations-page-dropdown-option-container{
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* width: 40%; */
}

.locations-page-dropdown-option{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: static;
  width: calc(100% - 10px);
  height: 59px;
  padding-left: 10px;
  left: 0px;
  top: 118px;
  background: #606060;
  box-shadow: inset 0px -1px 0px #CCCCCC;
  margin: 0px 0px;
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.04em;
  color: #FAFAFA;
  cursor:pointer;
}

.locations-page-dropdown-option:hover{
  color: #FC9601;
}

.locations-page-button-mobile{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  width: 100%;
  height: 40px;
  background: #409BFF;
  margin-bottom: 5px;
  border-radius: 20px;
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FAFAFA;
}

.locations-page-full-dropdown-container-mobile{
  width: 100%;
}

.locations-page-dropdown-mobile{
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FC9601;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #FC9601;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.locations-page-dropdown-option-container-mobile{
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.locations-page-dropdown-option-mobile{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: static;
  width: calc(100% - 10px);
  height: 59px;
  padding-left: 10px;
  left: 0px;
  top: 118px;
  background: #606060;
  box-shadow: inset 0px -1px 0px #CCCCCC;
  margin: 0px 0px;
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #FAFAFA;
}

.locations-page-state-bar-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.locations-page-state-bar{
  width: 100%;
  height: 35px;
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #FAFAFA;
  border-bottom: 1px solid #FAFAFA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom:12px;
  padding-bottom:4px;
}

.locations-page-state-bar-mobile{
  width: 100%;
  height: 35px;
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FAFAFA;
  border-bottom: 1px solid #FAFAFA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom:16px;
  padding-bottom:3px;
}


.locations-page-rest-card-container{
  width: 100%;
}

.locations-page-search-desktop-map{
  width:calc(100vw - 506px);
  height:calc(100vh - 76px);
  position:sticky !important;
  top:76px;
  object-fit: cover;
}

.locations-page-search-mobile-map{
  object-fit: cover;
}

.locations-page-search-mobile-map, .locations-page-search-mobile-static-map{
  margin-top:55px;
}

.locations-search-container{
  position:relative;
  background: #343A40;
  padding-left:30px;
  padding-right:30px;
  width:506px;
  padding-top:calc(30px + 76px);
  padding-bottom:25px;
  box-sizing:border-box;
  overflow-x: hidden;
  box-sizing:border-box;
}

.locations-search-title{
  color:#FC9601;
  font-weight:400;
  font-size:42px;
  font-family:Connection Serif;
}

.locations-search-subtitle{
  color:#FAFAFA;
  font-size:18px;
  font-weight:700;
  margin-top:20px;
  font-family:Space Mono;
}

.locations-search-bitcoin-pizza-price-container{
  width:100%;
  margin-top:20px;
  margin-bottom:20px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.locations-search-bitcoin-pizza-price-box{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  width:48%;
  border-color:#FC9601;
  height:78px;
  box-sizing:border-box;
  padding:10px 10px;
  border:4px solid #FC9601;
  position:relative;
}

.locations-search-bitcoin-circle{
  width:36px;
  height:36px;
  border-radius:36px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#FC9601;
  margin-right:10px;
}

.locations-search-bitcoin-circle svg{
}

.locations-search-pizza-img{
  width:36px;
  height:36px;
  display:block;
  margin-right:10px;
}

.locations-search-bitcoin-pizza-price-box-right-side{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
}
    
.locations-search-bitcoin-pizza-price-box-right-side-title{
  font-size:16px;
  font-weight:400;
  font-family:Connection Serif;
  color:#CCCCCC;
  margin-bottom:2px;
}

.locations-search-bitcoin-pizza-price-box-right-side-subtitle{
  font-family: VCR OSD Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #FAFAFA;
  margin-top:2px;
}

.border-tr{
  background-color:#343A40;
  z-index:99;
  position: absolute;
  top: -4px;
  right: -4px;
  width: 4px;
  height: 4px;
}

.border-tl{
  background-color:#343A40;
  z-index:99;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 4px;
  height: 4px;
}

.border-bl{
  background-color:#343A40;
  z-index:99;
  position: absolute;
  bottom: -4px;
  left: -4px;
  width: 4px;
  height: 4px;
}

.border-br{
  background-color:#343A40;
  z-index:99;
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 4px;
  height: 4px;
}

.pickup-delivery-selected{
  /*margin-top:20px;*/
  width:100%;
  text-align:center;
  cursor:pointer;
  position:relative;
}

.pickup-delivery-selected-pickup-click{
  width:50%;
  height:100%;
  left:0px;
  top:0px;
  position:absolute;
  z-indez:9;
  opacity:0;
}

.pickup-delivery-selected-delivery-click{
  width:50%;
  height:100%;
  right:0px;
  top:0px;
  position:absolute;
  z-indez:9;
  opacity:0;
}

.location-search-autocomplete-wrapper{
  margin-top:20px;
  margin-bottom:20px;
}


.location-search-autocomplete-wrapper .autocomplete-dropdown-container{
  border-width:0px;
}

.location-search-autocomplete-wrapper .places-input{
  font-size:18px;
  font-weight:400;
  font-family:Space Mono;
  color:#FAFAFA;
  background-color:#343A40;
  border-top:0px;
  border-left:0px;
  border-right:0px;
}

.location-search-autocomplete-wrapper .places-autocomplete-wrapper{
  border-top:0px;
  border-left:0px;
  border-right:0px;
  border-bottom: 1px solid #FC9601;
}

.location-search-autocomplete-wrapper .places-item{
  background: #171717;
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #FAFAFA;
  border-width:0px;
}

.location-search-autocomplete-wrapper .places-container{
  background-color:#343A40;
}

.locations-page-ressults-container{
  width:100%;
  margin-top:20px;
  padding-bottom:30px;
}

.locations-page-no-results{
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:35px;
  padding-bottom:50px;
}

.locations-page-no-locations{
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #CCCCCC;
  margin-top:20px;
}

.locations-search-desktop-header{
  height: 76px;
  background: #262525;
  width:100%;
  position:fixed;
  top:0px;
  z-index:9999;
  display:flex;
  flex-direction:row;
  align-items:center;
}

.locations-search-desktop-header-img{
  height:50px;
  margin-left:30px;
}

.locations-search-mobile-header{
  height: 55px;
  background: #262525;
  width:100%;
  position:fixed;
  top:0px;
  z-index:9999;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.locations-search-mobile-header-img{
  height:40px;
}

.see-all-locations{
  font-family: Space Mono;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #409BFF;
  position:absolute;
  bottom:0px;
  left: 0px;
  text-align:center;
  width:100%;
  padding-top:15px;
  padding-bottom:20px;
  cursor:pointer;
}

.see-all-locations:hover{
  text-decoration:underline;
}

.locations-page-enter-email-container{
  width:100%;
  padding-bottom:8px;
  margin-top:10px;
  border-bottom:1px solid #409BFF;
}

.locations-page-enter-email{
  width:100%;
  font-family: Space Mono;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #409BFF;
  background: #343A40;
  border:none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.locations-page-email-button-thank-you{
  color: #409BFF !important;
  background-color: #343A40 !important;
  border: 1px solid #409BFF !important;
}

.locations-page-enter-email::placeholder{
  color: #409BFF;
}

.locations-page-email-button{
  box-sizing:border-box;
  padding-left:15px;
  padding-right:15px;
  margin-top:20px;
  color: #409BFF;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #FAFAFA;
  background-color:#C7CBD1;
  height:40px;
  border-radius:20px;
  cursor:pointer;
  font-family:Space Mono;
}

.locations-page-email-button-enabled{
  background-color:#409BFF;
}

@media only screen and (min-width: 851px) {
  .locations-search-container{
    min-height:100vh;
  }

  .pickup-delivery-selected svg{
    width:446px;
    height:66px;
  }


}

@media only screen and (max-width: 850px) {
  .locations-search-container{
    width:100%;
    padding-top:20px;
  }


  .pickup-delivery-selected{
    margin-top:20px;
  }

  .autocomplete-dropdown-container{
  }

  .locations-search-title{
    font-size:28px;
  }

  .locations-search-subtitle{
    font-size:16px;
    margin-top:10px;
  }

  .locations-search-pizza-img{
    width:28px;
    height:28px;
  }

  .locations-search-bitcoin-circle{
    width:28px;
    height:28px;
  }

  .locations-search-bitcoin-pizza-price-box{
    height:57px;
    border-width:2px;
  }

  .locations-search-bitcoin-pizza-price-box-right-side-title{
    font-size:14px;
  }

  .locations-search-bitcoin-pizza-price-box-right-side-subtitle{
    font-size:15px;
    display:flex;
  }

  .locations-search-bitcoin-pizza-price-box svg{
    height:18px;
  }

  .pickup-delivery-selected svg{
    width:100%;
  }

  .location-search-autocomplete-wrapper .places-input{
    font-size:16px;
  }

  .location-search-autocomplete-wrapper .places-item{
    font-size:16px;
  }

  .locations-page-no-locations{
    font-size:16px;
  }

  .locations-page-enter-email{
    font-size:14px;
  }

  .locations-search-bitcoin-pizza-price-container{
    margin-top:15px;
    margin-bottom:15px;
  }

  .location-search-autocomplete-wrapper{
    margin-top:10px;
    margin-bottom:15px;
  }

}

/*
@media only screen and (max-height: 750px) {
  .locations-search-title{
    margin-top:76px;
  }
  .locations-search-container{
    margin-top:76px;
  }
  .locations-page-search-mobile-map{
    margin-top:0px;
    display:none;
  }
  .locations-page-mobile-container{
    min-height:100%;
    box-sizing:border-box;
    background-color: #343A40;
  }
}
*/

@media only screen and (max-height: 500px) and (max-width:700px) {
  .see-all-locations{
    display:none;
  }
}



@font-face {
  font-family:Connection Serif;
  src: url("/public/fonts/ConnectionSerif.ttf") format("truetype");
}

@font-face {
  font-family: 'VCR OSD Mono';
  font-style: normal;
  font-weight: normal;
  src: url('/public/fonts/VCR_OSD_MONO_1.001[1].woff') format('woff');
}


.restaurant-card-container-all-locations, .restaurant-card-container-all-locations-middle{
  width:33.3%;
  display:flex;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

