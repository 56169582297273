.NoAddressMessage {
  background: #fff;
  color: #777;
  margin: 0;
  border-radius: 0px 0px 16px 16px;
  animation: delay-enter 1s forwards ease;
  text-align: center;
}

@keyframes delay-enter {
  0% {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  80% {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  100% {
    opacity: 1;
    height: auto;
    padding: 10px;
  }
}

.location-search-container {
  display: inline-block;
  width: 100%;
  text-align: left;
  background: #fff;
  border-radius: 6px;
}
