.recentOrderPage{
  background: #fff;
  width: 100%;
}

.recentOrderHeader{
  display: inline-block;
  padding: 24px 18px;
  font-size: 32px;
  font-weight: 900;
  width: calc(100% - 36px);
}

.recentOrderContainer{
  display: inline-block;
  position: relative;
  width: calc(100% - 60px);
  margin: 6px 18px;
  padding: 12px 12px;
  border-radius: 24px;
  text-align: left;
  cursor: pointer;
  color: #111;
  transition: all .1s;
}

.recentOrderContainer:hover{
  color: #6BADF4;
  background: #eeeeee44;
}

.recentOrderTitle{
  display: inline-block;
  position: relative;
  font-size: 18px;
  font-weight: 300;
}

.recentOrderBody{
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  margin: 12px 12px 0px 12px;
  color: #333;
}

.recentOrderEmptyText{
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  padding: 12px 24px 48px 24px;
  color: #777;
}

.recentOrderHeavyText{
  font-weight: 600;
  color:#aaa;
}

.recentOrderDivider{
  width: calc(100% - 48px);
  margin: 0px 24px;
  background: #eee;
  height: 1px;
}