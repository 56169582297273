.RestaurantHeader{
  min-height: 60px;
  box-shadow: 0px 8px 16px -4px rgba(0,0,0,.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 997;
  padding-top: env(safe-area-inset-top, 0px);
}

.RestaurantHeaderMenu{
  position: absolute;
  right: 0;
  top: env(safe-area-inset-top, 0px) !important;
  height: 28px;
  padding: 16px 24px;
  cursor: pointer;
}

.header-wallet-button-container{
  position: absolute;
  right: 67px;
  top: 10px;
  height: 28px;
  cursor: pointer;
}

.RestaurantHeaderBackArrow{
  position: absolute;
  left: 0;
  top: var(--inset-top);
  height: 28px;
  padding: 16px 24px;
  cursor: pointer;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.RestaurantHeaderLogo{
  height: 48px;
  padding: 4px;
  transition: all .2s;
  display: block;
}

.RestaurantHeaderLogo:hover{
  height: 52px;
  padding: 2px;
  cursor: pointer;
}

.RestaurantHeaderProfile{
  position: absolute;
  right: 60px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  vertical-align: middle;
  cursor: pointer;
  max-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.solana-tool-tip-thing{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  right: 67px;
  top: 10px;
  cursor: pointer;
}

.solana-tooltip{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  width:260px;
  line-height: 27px;
  color: #FFFFFF;
}

.back-to-supperclub{
  width:100%;
  height:var(--supperclub-back-height);
  top:calc(60px + var(--inset-top));
  display:flex;
  align-items:center;
  font-size: 16px;
  color: #424242;
  background: #EEEEEE;
  font-family:'Space Grotesk';
  position:fixed;
  z-index:9;
  padding-left:12px;
}


.back-to-supperclub svg{
  height: 20px;
  margin-right: 5px;
}
