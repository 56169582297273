.radio-inner {
  width: 11px;
  height: 11px;
  background-color: #222222;
  border-radius: 11px;
  animation: bounce-in-radio 200ms;
}

.hngr-radio {
  width: 17px;
  height: 17px;
  border: 1px solid #cccccc;
  border-radius: 15px;
}

.checked {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: bounce-in-container 200ms;
}

@keyframes bounce-in-container {
  from {
    transform: scaleY(1);
    transform: scaleX(1);
  }
  to {
    transform: scaleY(1.1);
    transform: scaleX(1.1);
  }
}

@keyframes bounce-in-radio {
  from {
    transform: scaleY(0);
    transform: scaleX(0);
  }

  to {
    transform: scaleY(1.1);
    transform: scaleX(1.1);
  }
}
