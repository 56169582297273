.error-boundary > .footer {
  left: 0px;
}

.error-boundary {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.error-boundary img {
  margin-top: 30px;
  margin-bottom: 70px;
}

.error-boundary-home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 51px;
  background: #39f376;
  border-radius: 50px;
  font-size: 16px;
  text-align: center;
  color: #1c1c1c;
}
