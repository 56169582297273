.restaurant-card{
  box-sizing:border-box;
  width:100%;
  display:flex;
  flex-direction:column;
  background-color:#606060;
  margin-bottom:10px;
  padding:8px;
  border-radius: 6px;
  cursor:pointer;
}

.restaurant-card:hover{
  background-color:#595959;
}

.restaurant-card-title{
  font-family: Space Mono;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #FC9601;
  text-transform: uppercase;
  margin-bottom:5px;
}

.restaurant-card-address{
  font-family: Space Mono;
  font-size: 16px;
  color: #FAFAFA;
}

.restaurant-card-bottom-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  margin-top:13px;
}

.restaurant-card-order-button{
  font-family: Space Mono;
  font-weight: bold;
  font-size: 14px;
  color:#FAFAFA;
  display:flex;
  align-items:center;
  text-transform: capitalize;
  height:37px;
  border-radius:37px;
  background: #FC9601;
  padding-left:10px;
  padding-right:10px;
}

.restaurant-card-hours-container{
  display:flex;
  flex-direction:column;
}

.restaurant-card-open-today{
  font-family: Space Mono;
  font-weight: bold;
  font-size: 16px;
  color: #CCCCCC;
}

.restaurant-card-hours{
  font-family: Space Mono;
  font-weight: normal;
  font-size: 16px;
  color: #FAFAFA;
}

.restaurant-card-highlighted{
  border:1px solid #FC9601;
}

