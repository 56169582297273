.menu-item-footer{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  width:100%;
  padding-left:16px;
  padding-right:16px;
  box-sizing: border-box;
}

.menu-item-add-to-cart{
  height:38px;
  display:block;
  font-size:16px;
  font-weight:700;
  width:calc(100% - 114px - 16px);
  border-radius:6px;
  background-color:#AEAEAE;
  display:flex;
  align-items:center;
  justify-content:center;
  color:white;
  cursor:pointer;
}

.add-to-cart-enabled{
  background-color:var(--rest-secondary);
}

.add-to-cart-disabled{
  pointer-events:none;
}

.menu-item-expanded-container-no-image{
  padding-left:16px;
  padding-right:16px;
}

.menu-item-expanded-container-image{
  padding-left:16px;
  padding-right:16px;
}

.menuItemContent {
  height: 100%;
  position: relative;
  padding-bottom: 48px;
}

.menuItemContent-mobile{
  box-sizing:border-box;
  position: relative;
  padding-bottom: 48px;
}

.menu-item-title{
  position:sticky;
  top:0px;
  background-color:white;
  padding-top:13px;
  padding-bottom:13px;
}
