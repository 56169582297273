.checkerpage-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-left:10px;
  padding-right:10px;
  padding-bottom:36px;
  position:relative;
}

.checkerpage-rest-info-container{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  border-bottom: 1px solid #E0E0E0;
  width:100%;
  padding-bottom: 12px;
}

.checkerpage-rest-logo{
  width:120px;
}

.checkerpage-rest-name{
  font-weight:900;
  font-size:20px;
  line-height:27px;
  margin-top:12px;
  margin-bottom:6px;
}

.checkerpage-rest-name-secondary{
  font-weight:700px;
  font-size:16px;
  line-height:16px;
}

.checekerpage-pickup-delivery-container{
  display:flex;
  flex-direction:row;
  width:100%;
  justify-content:space-between;
  padding-top:25px;
}

.checkerpage-pickup-delivery-selector{
  cursor:pointer;
  box-sizing:border-box;
  width:calc(50% - 4px);
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  font-weight:800;
  font-size:16px;
  border-radius:3px;
  padding:0px 7px;
  height:36px;
  border:1px solid #E0E0E0;
  color: var(--rest-secondary);
  background-color:#F6F6F6;
  position:relative;
}

.one-option{
  width:100%;
}

.checker-page-pickup-delivery-selected{
  border:2px solid var(--rest-secondary);
}

.checkerpage-prep-time{
  font-weight:700;
  font-size:12px;
  box-sizing:border-box;
  border-radius:15px;
  border:1px solid var(--rest-secondary);
  padding: 3px 7px;
  display:inline-block;
}

.checkerpage-rightside-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.checkerpage-rightside-wrapper, .hidden-one-option{
  width:60px;
  text-align:right;
}

.checker-page-pickup-delivery-selected .checkerpage-prep-time{
  color:white;
  background-color: var(--rest-secondary);
}

.checkerpage-timeselect-container{
  width:100%;
  padding-top:25px;
  /*padding-bottom:25px;*/
  padding-bottom:0px;
}

.checkerpage-go-button{
  cursor:pointer;
  /*
  position:absolute;
  bottom: 0px;
  */
  position:relative;
  width:calc(100% + 32px) !important;
  left:-16px;

  z-index:99;
  height:48px;
  width:100%;
  background-color: var(--rest-secondary);
  font-size:16px;
  font-weight:700;
  color:white;
  padding-left:26px;
  padding-right:26px;
  box-sizing:border-box;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
}

.checkerpage-go-button-disabled{
  background-color: #AEAEAE;
}

.checkerpage-start-order{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
}

.checkerpage-start-order svg{
  fill:white;
  height:16px;
}

.autocomplete-dropdown-container{
  z-index:999;
  font-size: 16px;
  color: #555;
  font-weight: 400;
  position: absolute;
  border-radius: 0px 0px 15px 15px;
  width: calc(100% - 0px);
  border: 1px solid #eee;
  border-top: 0;
  /*top: 42px; */
  left: -1px;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  top: 35px;

}

.autocomplete-dropdown-container:empty {
  display: none;
}

.checkerpage-google-places-wrapper{
  padding-top:29px;
  width:100%;
}

.checkerpage-future-order-icon svg{
  fill: var(--rest-secondary);
  height:22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkerpage-future-order-icon *{
  fill: var(--rest-secondary);
  stroke: var(--rest-secondary);
}

.checkerpage-order-later-only{
  color:#AEAEAE;
  font-size:12px;
  font-weight:600;
  position:absolute;
  bottom:-21px;
}

.checkerpage-currently-unavailable{
  color:black;
  font-size:12px;
  font-weight:600;
  position:absolute;
  bottom:-21px;
}

.checkerpage-order-later-only svg{
  fill:#AEAEAE;
  height:12px;
  width:12px;
  position:relative;
  top:2px;
  left:2px;
}

.checkerpage-were-closed-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  width:100%;
  font-size:14px;
}

.earliest-time-header{
  font-weight:700;
  font-size:18px;
  padding-top:12px;
  padding-bottom:4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1900%;
}


.earliest-time-header svg{
  margin-right:7px;
}

.were-closed{
  font-weight:700;
  font-size:14px;
  padding-top:12px;
  padding-bottom:8px;
}

.checkerpage-browse-our-menu{
  font-size:14px;
  font-weight:700;
  color: var(--rest-secondary);
  border-radius:3px;
  background-color: #F5F5F5;
  width:100%;
  height:36px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  margin-top:20px;
  cursor:pointer;
}

.select-time-to-schedule-order{
  padding-top:10px;
  font-size:14px;
  font-weight:700;
}

.checkerpage-closed-hourglass{
  height:61px;
  margin-top:15px;
}

.checkerpage-closed-sign{
  fill: #C0BBBB;
  height: 75px;
}

.checker-option-disabled{
  opacity:0.5;
  pointer-events:none;
}
