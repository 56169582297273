@import "../../css/foodcoinStandards.scss";
@import "../CheckoutSelectPayment/CheckoutSelectPayment.module.scss";
@import "../../css/Checkout.module.scss";

.container {
  @extend %text-rules;
}

%card {
  border-radius: 6px;
  border: none;
  display: flex;
  align-items: center;
  padding: 12px;
  // background-color: #eaecef;
  color: #343941;
  width: 100%;
  font-size: 16px;
  transition: transform 150ms ease-in, box-shadow 150ms ease-in;
}

.checkout {
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
}

.add {
  @extend %card;
  background: #dce9f0;
  box-sizing: border-box;
  color: #417d9d;
  font-weight: 600;
  transition: background-color 100ms linear;
  &:hover {
    cursor: pointer;
    background-color: #b9d1de;
    //   transform: translateY(-5px);
    //   box-shadow: 0px 5px 7px 0px #e7e7e7;
  }
}

.savedCard {
  // border-radius: 6px;
  // border: none;
  // display: flex;

  // padding: 8px;

  @extend %card;
  text-align: left;
  justify-content: space-between;
  color: $fc-black;
  box-sizing: border-box;
  background-color: #eee;
  transition: transform 150ms ease-in, box-shadow 150ms ease-in;
  &:hover {
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0px 5px 7px 0px #ededed;
    background: rgba(66, 132, 244, .24)
  }
}

.useSaved {
  @extend .savedCard;
  justify-content: flex-start;
}

.addContainer {
  margin: 8px 0;
  width: 100%;
}

.card {
  display: inline-block;
  color: #424770;
  letter-spacing: 0.025em;
  font-family: Source Code Pro, monospace;
  font-size: 16pt;
  &::placeholder {
    color: #aab7c4;
  }
}

.paymentContainer {
  width: 75%;
  background-color: white;
  box-shadow: 1px 1px 10px 1px lightgray;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  padding-bottom: 10px;
  max-width: 300px;
  margin: 0 auto;
  height: 160px;
}

%action {
  margin: 8px;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.action {
  &__submit {
    @extend %action;

    color: white;
    border-radius: 35px;
    padding: 14px 20px;
    font-size: 12px;
    font-weight: 600;
    width: 100px;
  }

  &__back {
    @extend %action;
    @extend %change-button;
    background: none;
    padding: 14px 20px;
  }
}

.actionContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin: 16px 0;
}

.saveContainer {
  display: flex;
  justify-content: center;
  padding: 16px;
  align-items: center;
  &__child {
    margin: 8px;
  }
}

.spinner {
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
  position: relative;
  padding: 10px;
  &::before {
    animation: spinner 750ms ease-out infinite;
    border: solid 3px #eee;
    border-bottom-color: #ef6565;
    border-radius: 50%;
    content: "";
    height: 15px;
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    width: 15px;
    will-change: transform;
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
  flex-wrap: wrap;

  // & > div {
  //   margin: 0 8px;
  // }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.cardLogo {
  height: 12px;
}

.selected {
  @extend %card;
  background-color: white;
  color: $fc-black;
  // font-weight: 500;
  justify-content: space-between;
  width: auto;

  &:hover {
    cursor: auto;
  }
}

.cardTag {
  font-size: 12px;
  color: #adb5bd;
  margin-right: 4px;
}

.applePay {
  @extend .button;
  background-color: #22252b;
  justify-content: center;
  color: white;
  letter-spacing: 0;
  font-weight: 500;
}

.applePaySelected {
  border: none;
  font-size: 16px;
}

.default {
  border: none;
  border-right: 1px solid #858e96;
  // max-width: 64px;
  @extend %text-rules;
  font-weight: 500;
  background: none;
  font-size: 8px;
  color: #adb5bd;
  &:hover {
    cursor: pointer;
    color: #495057;
  }
}

.delete {
  @extend .default;
  border: none;
}

.actions {
  display: flex;
  align-items: center;
}

.saveContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  &__child {
    margin: 8px;
  }
}
