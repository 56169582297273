.places-input{
  border-radius: 15px;
  width: 100%; 
  display: inline-block;
  height: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 0px;
  font-size: 16px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius:3px;
  padding-left:7px;
  padding-right:45px;
  font-family:"Nunito Sans", sans-serif;
}

.places-input-error{
  background-color:rgb(234, 81, 81, 0.1);
  border: 1px solid #EA5151;
  line-height:10px;
}

.places-item-active{
  background-color: #6BADF4 !important;
  color: #fff;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12pt;
  font-weight: 700;
  text-align: left;
}

.places-item {
  border-left: 1px solid #a8a8a8;
  border-right: 1px solid #a8a8a8;
  background-color: #ffffff;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  position:relative;
  width:50%;
  box-sizing:border-box;

}

.places-container{
  box-sizing:border-box;
  left:-50%;
  position:relative;
  background-color:white;
  z-index:999;
  width:200%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.places-autocomplete-wrapper {
  display: block;
  border-radius: 3px;
  border: 1px solid #a8a8a8;
  position:relative;
}

.places-autocomplete-error{
  position:absolute;
  top:40px;
  font-weight: 600;
  font-size: 14px;
  color: #EA5151;
  padding-left:7px;
  line-height:13px;
}

.places-autocomplet-container {
  position: relative;
  width: 100%;
  z-index:999;
}

.autocomplete-curloc{
  cursor:pointer;
  z-index:990;
  position:absolute;
  border-radius: 5px;
  right: 4px;
  top: 3px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  background-color: var(--rest-secondary);
}

.autocomplete-curloc svg{
  padding:8px;
}

.autocomplete-curloc path{
  fill:white;
}
