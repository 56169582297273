$checkbox-border-color: #cccccc;

.checkbox {
  height: 20px;
  width: 20px;
  border-radius: 3px;

  border: 1px solid $checkbox-border-color;

  &:hover {
    border: 1px solid grey;
  }

  &__checked {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $checkbox-border-color;
    border-radius: 3px;
  }

  &__inner {
    height: 16px;
    border-radius: 3px;

    width: 16px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: bounce-in 200ms;
    animation-direction: alternate;
  }
}

@keyframes bounce-in {
  from {
    // transform: scaleX(0);
    transform: scaleY(0);
  }
  to {
    // transform: scaleX(1);
    transform: scaleY(1.1);
  }
}
