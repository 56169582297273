.menu-item-wrapper {
  display: flex;
  position: relative;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #fff;
  margin: 8px;

  width: calc(33% - 16px); /* flex: 0 31%; */
  transition: all 0.2s;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: top;
  overflow: hidden;
  /* min-width: 350px; */
}

.menu-item-wrapper-top-img{
  display: flex;
  position: relative;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #fff;
  margin: 8px;

  width: calc(25% - 16px);
  transition: all 0.2s;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: top;
  overflow: hidden;
  /* min-width: 350px; */
}

.menu-item-wrapper--side-image {
  width: calc(50% - 16px);
}
@media only screen and (min-width: 800px) and (max-width: 880px) {
  .menu-item-wrapper {
    flex: 1 100%;
  }
}

.menu-item-wrapper-featured{
  display: flex;
  position: relative;
  border: 1px solid;
  border-radius: 4px;
  background: #fff;
  margin: 8px;

  width: calc(25% - 16px);
  transition: all 0.2s;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: top;
  overflow: hidden;
}

.MenuItemNameFeatured{
  padding:10px;
}

.menu-item__title-desc-container {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.menu-item__text-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 4px;
}

.MenuItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px 16px 32px 16px;
}

.menu-item-wrapper-top-img .MenuItem{
  box-sizing:border-box;
}

.MenuItemEmpty {
  min-width: calc(50% - 16px);
  padding: 16px;
}

.MenuItemHeaderEmpty {
  position: relative;
  height: 24px;
  background: linear-gradient(-45deg, #999, #ddd, #999, #ddd);
  background-size: 400% 400%;
  animation: gradientBG 1.8s ease infinite;
  opacity: 0.2;
  margin-bottom: 8px;
}

.MenuCategoriesEmpty {
  position: fixed;
  top: 60px;
  height: 60px;
  left: 0;
  background-color: white;
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 80%;

  width: 100%;
  padding: 16px 32px;
  box-shadow: 0 1px 9px 0 rgba(193, 193, 193, 0.5);
  z-index: 996;
}

.MenuItemDescriptionEmpty {
  height: 12px;
  background: linear-gradient(-45deg, #999, #ddd, #999, #ddd);
  background-size: 400% 400%;
  animation: gradientBG 1.8s ease infinite;
  animation-delay: 0.9s;
  opacity: 0.1;
  margin: 4px 16px 4px 0px;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.MenuItemWithImg {
  padding: 0;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 120px;
}

.menu-item-wrapper:hover:not(.BlackedOut), .menu-item-wrapper-featured:hover {
  box-shadow: 0px 4px 16px -2px rgba(107, 86, 86, 0.2);
  transform: translate(-1px, -1px);
}

.menu-item-wrapper:hover:not(.BlackedOut) > .MenuItemHeader, .menu-item-wrapper-featured:hover > .MenuItemHeader {
  color: #4284f4;
}

.MenuItemHeader {
  font-size: 18px;
  font-weight: 900;
  color: #222;
  transition: all 0.2s;
  padding-top: 8px;
}

.menu-item-wrapper-top-img .MenuItemHeader{
  margin-top:15px;
}

.MenuItemPrice {
  color: #127c37;
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  bottom: 8px;
}

.menu-item-price--side-img {
  color: #127c37;
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  right: 5px;
  border-radius: 3px;
  top: 8px;
  font-weight: 800;
  padding: 3px;
}

.menu-item-price--side-img {
  background: hsla(0, 0%, 100%, 0.9);
}

.menu-item-price--featured {
  color: #127c37;
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  right: 5px;
  border-radius: 3px;
  top: 8px;
  font-weight: 800;
  padding: 3px;
  background: hsla(0, 0%, 100%, 0.9);
}



.MenuItemDescription {
  padding-right: 16px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #888;
  max-height: 3.9em;
  min-height: 1.3em;
  line-height: 1.3em;
  text-overflow: ellipsis;
  overflow: hidden;
}

.menu-item-description--with-image {
  /* overflow: inherit; */
}

.menu-item-description--overflow::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32px;

  background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255) 80%);
}

.menu-item-description--overflow--with-image::after {
  width: calc(100% - 120px);
}

@media only screen and (max-width: 800px) {
  .MenuItem {
    max-width: 1000px;
  }
  .MenuItemDescription {
    min-height: auto;
  }

  .MenuItemHeader {
    font-size: 16px;
    font-weight: 800;
  }

  .menu-item-wrapper {
    flex: 1 100%;
  }

  .menu-item-wrapper-top-img {
    width: calc(50% - 16px);
  }
}

.menu-item__header__container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 4px;
  justify-content: space-between;
}

.menu-item--temp-out__wrapper {
  background-color: #eff0f4;
  border-radius: 15px;
  padding: 4px 12px;
}

.menu-item--temp-out {
  font-size: 12px;
  white-space: nowrap;
  color: #2f2e31;
  font-weight: 600;
}

.MenuItemImgSide {
  height: 120px;
  width: 120px;
  min-height: 120px;
  min-width: 120px;
}


.MenuItemImgTop {
  width:100%;
}

.BlackedOut {
  opacity: 0.5;
  cursor: not-allowed;
}

.HiddenItem {
  display: none !important;
}
