.quantityContainer{
  display:flex;
  flex-direction:row;
  border: 1px solid #e0e0e0;
  border-radius:6px;
}

.quantityPlus, .quantityMinus, .quantityQuantity{
  width:38px;
  height:38px;
  display:flex;
  align-items:center;
  justify-content:center;
} 

.quantityMinus{
  background-color:#F9F9F9;
  border-right: 1px solid #e0e0e0;
  cursor:pointer;
}

.quantityDisabled svg{
  fill:#AEAEAE;
}

.quantityDisabled{
  pointer-events:none;
}

.quantityPlus{
  background-color:#F9F9F9;
  border-left: 1px solid #e0e0e0;
  cursor:pointer;
}


.quantityMinus{
  background-color:#F9F9F9;
  border-right: 1px solid #e0e0e0;
}

.quantityContainer svg{
  width:18px;
  height:18px;
  stroke-width:2px;
}


.quantityContainer path{
  stroke-width:2px;
}

.quantityQuantity{
  font-size:20px;
  font-weight:800;
}
