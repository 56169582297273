@function spacing($size) {
  $result: 1;
  $result: $size * 0.25;
  @return #{$result}rem;
}

@for $i from 0 through 20 {
  //margin utility

  .m-#{$i} {
    margin: spacing($i);
  }

  .mv-#{$i} {
    margin-top: spacing($i);
    margin-bottom: spacing($i);
  }

  .mh-#{$i} {
    margin-left: spacing($i);
    margin-right: spacing($i);
  }

  .mt-#{$i} {
    margin-top: spacing($i);
  }

  .mb-#{$i} {
    margin-bottom: spacing($i);
  }

  .mr-#{$i} {
    margin-right: spacing($i);
  }

  .ml-#{$i} {
    margin-left: spacing($i);
  }

  // Padding utility

  .p-#{$i} {
    padding: spacing($i);
  }

  .pv-#{$i} {
    padding-top: spacing($i);
    padding-bottom: spacing($i);
  }

  .ph-#{$i} {
    padding-left: spacing($i);
    padding-right: spacing($i);
  }

  .pt-#{$i} {
    padding-top: spacing($i);
  }

  .pb-#{$i} {
    padding-bottom: spacing($i);
  }

  .pr-#{$i} {
    padding-right: spacing($i);
  }

  .pl-#{$i} {
    padding-left: spacing($i);
  }
}

@for $i from 0 through 20 {
  .br-#{$i} {
    border-radius: #{$i}px;
  }

  .br-bl-#{$i} {
    border-bottom-left-radius: #{$i}px;
  }

  .br-br-#{$i} {
    border-bottom-right-radius: #{$i}px;
  }
}
