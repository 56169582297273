@import "./foodcoinStandards.scss";

.button {
  @extend .noButton;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    transform: scale(1.05, 1.05);
  }
}

.coupon {
  display: flex;
  flex-direction: column;
  max-width: 75%;
  margin: auto;
  justify-content: center;
}

.launchChecker{
  background: #fff;
  border:0;
  border-radius: 6px;
  color: #111;
  margin: 0px 72px;
  padding: 8px 16px;
  font-size: 12px;
  cursor: pointer;
  transition: all .05s;
}

.launchChecker:hover{
  transform: scale(1.01);
  box-shadow: 1px  5px 24px -12px #ccc;
}

.noButton {
  padding: 16px 64px;
  border: none;
  border-radius: 30px;
  margin: auto;
  display: flex;
  justify-self: center;
  margin-bottom: 64px;

  color: white;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;

  @extend %hover;
  // transition: transform 150ms cubic-bezier(0.07, 1.03, 0.58, 1);
}


