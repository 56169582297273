.time-select__option {
  font-weight: bold;
  color: black;
}

.timeSelectContainer {
  position: relative;
  height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.timeSelectContainer-new {
  position: relative;
  height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100% - 60px);
  width: 100%;
}

.timeSelectContainer--checkout {
  max-width: 100%;
  padding: 0 3rem;
  margin-top: 1rem;
}


.timeSelectContainer--checkout-new {
  max-width: 100%;
  margin-top: 1rem;
}

.timeSelectIcon {
  position: absolute;
  left: -26px;
  display: flex;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1rem;
}

.timeSelectIcon-new {
  position:relative;
  z-index:9;
}

.bg-responsive-svg,
.bg-responsive-svg > path {
  fill: var(--rest-secondary);
}

.time-select__partial-container {
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  position: relative;
  border: 1px solid #C4C4C4;
  border-radius:3px;
  padding:0px 7px;
  height:36px;
  width:calc(50% - 4px);
  box-sizing:border-box;
  font-size:14px;
  font-weight:600;
}

.time-select__partial-container-new{
  display: flex;
  position: relative;
  width: 49%;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding:0px 10px;
  box-sizing:border-box;
}

.textHeader {
  font-size: 24px;
  color: #555;
}

.selectdiv {
  position: relative;
  /* vertical-align: middle; */
  /* display: inline-block; */
  text-align: -webkit-left;
  border: 0;
  text-align-last: left ;

  text-align: left;
  width: 100%;
  display: flex;
}

.selectdiv:hover * {
  color: #4284f4;
  fill: #4284f4;
}

.selectdiv:hover + .timeSelectIcon > svg {
  color: #4284f4;
  fill: #4284f4;
}

select::-ms-expand {
  display: none;
}

.selectdiv select {
  text-align: -webkit-center;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: bold;
  color: black;
  background-color: #fff;
  /* text-align: -webkit-center;
  text-align: -moz-center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  text-align-last: center; */
  float: right;
  border: 0;
  cursor: pointer;
  transition: all 0.1s;
  width: 100%;
}

.selectdiv > svg {
  pointer-events: none;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}


.selectdiv select:hover {
  color: #4284f4;
}

.selectdivarrow {
  display: inline-block;
  position: relative;
  color: #ccc;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: -12px;
  pointer-events: none;
  vertical-align: middle;
}

.selectdivarrow:hover {
  color: #4284f4;
}


.selectdiv-new {
  position: relative;
  text-align: center;
  text-align: -webkit-center;
  text-align-last: center;
  width: 100%;
  border: 0;
  display: flex;
  z-index:1;
}

.selectdiv-new:hover * {
  color: #4284f4;
  fill: #4284f4;
}

.selectdiv-new:hover + .timeSelectIcon-new > svg {
  color: #4284f4;
  fill: #4284f4;
}

select::-ms-expand {
  display: none;
}

.selectdiv-new select {
  text-align: -webkit-center;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: bold;
  color: black;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  transition: all 0.1s;
  padding-right: 12px;
  padding-left: 14px;
  width: 100%;
}

.selectdiv-new > svg {
  pointer-events: none;
}


.selectdiv-new select:hover {
  color: #4284f4;
}

.divider {
  width: 2px;
  background: #eee;
  vertical-align: middle;
  height: 30px;
  display: inline-block;
  margin: 0px 16px;
}

.capitalize {
  text-transform: capitalize;
}

.edit {
  text-align: center;
  font-size: 16px;
  margin: 0;
  color: rgb(20.2%, 74.7%, 90.9%);
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 800px) {
  .selectdiv select {
  }

  .selectdiv > svg {
  }
  .timeSelectContainer {
  }

  .timeSelectContainer--checkout {
    max-width: 100%;
    padding: 0 1.5rem;
  }

  .timeSelectIcon {
    left: -23px;
  }

  .timeSelectClock {
    /*left: -10px;*/
    text-align: -webkit-right;
    text-align: right
  }

  .time-select__partial-container {

  }
}

@media only screen and (max-width: 880px) {
  .selectdiv-new select {
    font-size: 12px;
  }
}

.timeSelectContainer--pickup{
  margin-top: 29px;
  margin-bottom: 37px;
}

.time-select__middle{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  width:100%;
}

.time-select__overlay{
  width:50%;
  height:100%;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  font-weight: bold;
  position:relative;
  z-index:1;
  box-sizing:border-box;
  padding:0px 10px;
  color:black;
}

.time-select__hiden-select:hover ~ .time-select__overlay{
  color: #4284f4 !important;
}

.time-select__hiden-select{
  opacity:0;
  position:absolute;
  z-index:99;
  cursor:pointer;
}

.time-select__hiden-select select{
  width:100%;
  height:100%;
  cursor:pointer;
  text-align: -webkit-center;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  color: orange;
  background-color: #fff;
  cursor: pointer;
  padding-left: 5px;
}

.time-select__hiden-select select{
  color: #4284f4;
}

.time-select__open-icon {
  top: 50%;
  transform: translateY(-50%);
}

.timeSelectImg-container{
  height: 16px;
  width: 16px;
}

.time-select__first-icon{
  position:relative;
  top:-1px;
}

.timeselect-disabled{
  opacity:0.5;
  pointer-events:none;
  cursor:pointer;
}

.timeselect-disabled:hover{
  color:black !important;
}

.timeselect-disabled:hover *{
  color:black !important;
  fill: var(--rest-secondary) !important;
}

.checkerpage-select-date-warning{
  position:absolute;
  top:37px;
  color:#AEAEAE;
  font-size:12px;
  font-weight:600;
  opacity:1.0 !important;
  cursor:pointer;
}
