.orderReceiptContainer{
    position: relative;
    display: inline-block;
    max-height: 85%;
    margin-top: 5%;
    width: 80%;
    background: #fff;
    overflow:hidden;
    overflow-y: auto;
    max-width: 320px;
  }
  
  .orderReceiptFrame{
    width:100%;
    position: relative;
    top:0;
    left:0;
    border:0;
    overflow:hidden;
  }
  