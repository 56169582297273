@import "../../../css/foodcoinStandards.scss";

.illinois-modal__content {
  margin: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.illinois-modal__title {
  font-size: 24px;
  font-weight: 800;
  color: $fc-black;
  text-align: center;
}

.illinois-modal__button {
  font-size: 24px;
  margin: 12px 0;
  width: 100%;
  font-weight: 600;
  padding: 16px 24px;
}

.illinois-modal__button--secondary {
  // color: #4284f4;
  color: var(--bg-primary);
  background: transparent;

  &:hover {
    text-decoration: underline;
  }
}

.illinois-modal__button-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

@media only screen and (max-width: 400px) {
  .illinois-modal__content {
    margin: 24px;
  }
}
