
.abandoned-header {
  font-weight: 900;
  font-size: 24px;
  color: #222;
  letter-spacing: 1px;
  text-align: center;
  line-height: 42px;
  margin: 24px auto;
  position: relative;
  display: inline-block;
  width: 100%;
}

.abandoned-rest-info-container{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  border-bottom: 1px solid #E0E0E0;
  width:100%;
  padding: 12px;
}

.abandoned-rest-logo{
  width:120px;
}

.abandoned-rest-name{
  font-weight:900;
  font-size:20px;
  line-height:27px;
  margin-top:12px;
  margin-bottom:6px;
}

.abandoned-rest-name-secondary{
  font-weight:700px;
  font-size:16px;
  line-height:16px;
}

.abandoned-action-container{
  display:flex;
}
