.checkout-kiosk-diner-firstname{
  width:50%
}
.checkout-kiosk-diner-lastname{
  width:50%
}
.checkout-kiosk-diner-email{
  width:75%
}
.checkout-kiosk-diner-phone{
  width:40%
}
.checkout-kiosk-diner-prompt{
 font-size:18px;
 font-weight:600;
 margin:15px 0px;
}
