.select-payment-content{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  width:100%;
  box-sizing:border-box;
}

.checkout-payment-method-container{
  height:100px;
  background-color:#F3F3F3;
  border-radius:10px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin: 0px auto;
  box-sizing:border-box;
  cursor:pointer;
}

.payment-method-unselected{
  padding-left:19px !important;
  padding-right:19px !important;
}

.checkout-selected-payment{
  font-weight:bold;
  border:5px solid  var(--rest-secondary); 
  background-color: var(--rest-secondary-rgba-point1);
}

.card-number-input{
  width:95%;
  height:45px !important;
  box-sizing:border-box;
  padding:11px 15px 7px 15px !important;
  border: 1px solid #a8a8a8 !important;
}

.cvv-input{
  width:90%;
  height:45px !important;
  box-sizing:border-box;
  padding:11px 15px 7px 15px !important;
  border: 1px solid #a8a8a8 !important;
}

.expiration-input{
  width:90%;
  height:45px !important;
  box-sizing:border-box;
  padding:11px 15px 7px 15px !important;
  border: 1px solid #a8a8a8 !important;
}

.zipcode-input{
  text-align: left;
  font-family: sans-serif !important;
  height:45px !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #717171;
  box-sizing:border-box;
  padding:10px 15px !important;
  border: 1px solid #a8a8a8 !important;
}

.zipcode-input::placeholder{
  color:#aab7c4;
  font-weight:light;
}

.select-saved-card-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
}

.saved-card-container{
  padding-top:14px;
  padding-bottom:15px;
}

.saved-card-left{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
}

.bold{
  font-weight:bold;
}

.save-card-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  width: 50%;
  padding-top: 13px;
}

.save-card-text{
  font-size:15px;
  margin-right:9px;
}

.select-saved-card-outer-container, .checkout-card-section{
}

.checkout-payment-error {
  background-color: #ffe0e0;
  color: #d60000;
  padding: 12px;
  border-radius: 6px;
  margin: 12px 0;
  display: flex;
  width:100%;
  align-items: center;
  box-sizing:border-box;
}


.checkout-error-text{
  margin: 0 8px;
}

.delete-card-container{
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
}

.delete-card-container svg{
  fill:#EC0101 !important;
}

.checkout-custom-payment-section{
  background-color:#F3F3F3;
  padding-bottom:25px !important;
  padding-top:15px;
}

.checkout-payment-bitcoin svg{
  width:32px;
  height:32px;
}

.checkout-bitcoin-blurb{
  display:flex;
  flex-direction:column;
}

.checkout-opennode-blurb{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  border: 1px solid #FC9601; 
  padding:15px;
  margin-top:10px;
  box-sizing: border-box;
  border-radius: 4px;
}

.checkout-opennode-text{
  text-align:center;
  font-size:14px;
  font-weight:400;
}

.checkout-opennode-img-container{
  margin-bottom:15px;
}

.checkout-pay-with-bitcoin{
  font-weight:bold;
}

.checkout-bitcoin-note{
  color:#9B9B9B;
  font-size:12px;
  padding-top:20px;
}

.payment-method{
  font-weight:400;
  font-size:16px;
  color: #717171;
  padding:16px;
  border: 1px solid #C9C9C9;
  box-sizing: border-box;
  border-radius: 4px;
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-right:8px;
  height:54px;
  white-space:nowrap;
  margin-bottom:10px;
}   

.payment-method-selected{
  font-weight:700;
  color: #000AFF;
  background: rgba(0, 10, 255, 0.1);
  border: 4px solid #000AFF;
}

.payment-methods{
  display:flex;
  width:100%;
  flex-direction:row;
  align-items:flex-start;
  margin-bottom:20px;
  flex-wrap:wrap;
}

.add-new-card-text{
  font-weight: 600;
  font-size: 18px;
  color: #717171;
}

.cancel-card{
  cursor:pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  text-decoration-line: underline;
  color: #000AFF;
}

.checkout-elements-header{
  font-weight: 400;
  font-size: 14px;
  color: #717171;
  padding-bottom:4px;
}

.select-payment-bottom-shit{
  width:100%;
  min-height:230px;
  overflow-y:visible;
  background: #FAFAFA;
  border-radius: 6px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

.cash-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  text-align:center;
}

.cash-container svg{
  width:80px;
  height:80px;
}

.solana-connected.solana-container{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), linear-gradient(72.72deg, #8263ED -3.73%, #6B81DA 23.61%, #52A1C6 50.91%, #3DBBB6 76.54%, #24DCA2 103.23%);
}

.solana-container{
  background: linear-gradient(72.72deg, #8263ED -3.73%, #6B81DA 23.61%, #52A1C6 50.91%, #3DBBB6 76.54%, #24DCA2 103.23%);
}

.solana-top-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  font-weight: 700;
  font-size: 20px;
  color: #FFFFFF;
  height:50px;
  padding-left:15px;
  padding-right:15px;
}

.solana-bottom-portion{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  height:180px;
}

.solana-just-show-address *{
  pointer-events:none;
}

.solana-connected-bottom-portion{
  min-height:180px;
  box-sizing:border-box;
  padding-left:15px;
  padding-right:15px;
  padding-bottom:15px;
}

.solana-pay-with{
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #252525;
}

.solana-connected .solana-pay-type-container{
  border: 1px solid #252525 ;
  color: #252525;
  height:73px !important;
}

.solana-pay-type-container:hover{
  background: #F0F1FF;
}

.solana-payment-svg , .solana-payment-png{
  margin-left:0px;
  margin-right:8px;
  width:32px;
  height:32px;
}

.solana-pay-type-selected{
  background: #E5E7FF !important;
  border: 3px solid #000AFF !important;
  height:73px !important;
  box-sizing:border-box;
}

.solana-pay-disabled{
  background: #E8CFCF;
  border: 1px solid #EC0101;
  color: #EC0101;
}

.solana-pay-disabled *{
  color: #EC0101;
}

.solana-not-enough-funds{
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #EC0101;
  background: linear-gradient(0deg, #E8CFCF, #E8CFCF), #FFFFFF;
  border: 1px solid #FF0000;
  box-sizing: border-box;
  border-radius: 10px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  height:150px;
  padding-left:15px;
  padding-right:15px;
  padding-top:20px;
  padding-bottom:20px;
  margin-top:15px;
  width:calc(100% - 30px);
}

.select-payment-error{
  display: flex;
  flex-direction: row;
  justify-content:space-between;;
  align-items: center;
  padding: 4px 8px;
  background: rgba(255, 1, 1, 0.1);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  color: #EC0101;
  margin-top:15px;
  position:relative;
}

.select-payment-error-x{
  width:17px;
  height:17px;
  margin-right:10px;
  margin-left:10px;
  cursor:pointer;
}

.solana-option-name-amount-container{
  display:flex;
  flex-direction:column;
  height:100%;
  justify-content:space-between;
  margin-left:10px;
}

.solana-option-token-name{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.solana-option-token-amount{
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #262525;
}

.solana-connected .wallet-adapter-button{
padding:0px !important;
}

.solana-connected{
  border-style: solid;
  border-width: 5px;
  border-image: linear-gradient(72.72deg, #8263ED -3.73%, #6B81DA 23.61%, #52A1C6 50.91%, #3DBBB6 76.54%, #24DCA2 103.23%);
  border-image-slice:3;
  box-sizing:border-box;
  border-radius:6px;
}

.solana-payment-selected{
}

.checkout-supperclub-blurb{
  border-radius: 6px;
  background-color:black;
  padding: 18px 32px;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.checkout-supperclub-blurb img{
  width: 237px;
  height: 40px;
  margin-bottom:16px;
}

.checkout-supperclub-burb-txt{
  width: 320px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}

.supperclub-button{
  margin-top:20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  width: 320px;
  height: 59px;
  border: 3px solid #39F376;
  border-radius: 33px;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #39F376;
  font-family: 'Space Grotesk';
  cursor:pointer;
  text-decoration:none;
  margin-bottom:20px;
}

.supperclub-button:hover{
  color:white;
  border-color:white;
}

.also-phantom{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #B3B3B3;
  font-family: 'Space Grotesk';
}
