.MenuLoading{
  width: 100%;
  height: 72vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.MenuLoadingImg{
  width: 96px;
  padding: 8px;
  border-radius: 8px;
  animation: pulsing-logo 1.2s infinite ease;
}

.MenuLoadingText{
  font-size: 18px;
  font-weight: 900;
  color: #222;
  padding: 8px;
}

@keyframes pulsing-logo{
  0%{
    transform: scale(1);
    box-shadow: 0px 16px 32px -8px rgba(0,0,0,.24);
  }
  50%{
    transform: scale(1.06);
    box-shadow: 0px 16px 32px 0px rgba(0,0,0,.24);
  }
  100%{
    transform: scale(1);
    box-shadow: 0px 16px 32px -8px rgba(0,0,0,.24);
  }
}