@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap');

html{
  font-family: 'Nunito Sans', sans-serif;
}

.rewardsBackground {
  margin: auto;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 120px);
  padding-bottom: 120px;
  width: 100vw;
  top: 0;
  left: 0;
  background: #00000077;
  z-index: 998;
  overflow: hidden;
}

.rewardsContainer{
  position: fixed;
  width: calc(92vw - 32px);
  max-height: calc(96vh - 32px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
  max-width: 800px;
  background: #fff;
  margin: auto;
  border: 0;
  border-radius: 16px;
  text-align: center;
  animation: drop-in ease 500ms forwards;
  opacity: 0;
}

.rewardsClose{
  position: absolute;
  top: 16px;
  right: 16px;
  height: 24px;
  opacity: .4;
  cursor: pointer;
}

.rewardsHeader{
  text-align: left;
  font-size: 32px;
  font-weight: 800;
}

.rewardsPoints{
  font-size: 100px;
  font-weight: 100;
  line-height: .7;
  margin: 48px 0px 6px 0px;
  animation: slide-in ease 500ms forwards;
}

.rewardsPointsText{
  font-size: 42px;
  font-weight: 200;
  letter-spacing: 10px;
  line-height: 1;
  padding-right: 2px;
  margin-bottom: 32px;
  animation: slide-in ease 500ms forwards;
}

.rewardsButton{
  border: 0;
  box-shadow: 0;
  width: 90%;
  max-width: 350px;
  padding: 10px 0px;
  color: #fff;
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 800;
  border-radius: 10px;
  margin-bottom: 32px;
  animation: slide-in ease 500ms forwards;
}

.smallMargins{
  margin: 8px 0px;
}

.rewardsFinePrint{
  font-size: .8em;
  margin: 12px 0px;
}

.rewardsKeycode{
  font-size: 56px;
  font-weight: 900;
  margin: 48px 0px 0px 0px;
  line-height: .8;
  letter-spacing: 1px;
  animation: slide-in ease 500ms forwards;
}

.rewardsKeycodeText{
  font-size: 24px;
  font-weight: 700;
  color: #222;
  margin-bottom: 48px;
  animation: slide-in ease 500ms forwards;
}

.birthdayInput{
  height: 64px;
  width: 64px;
  font-size: 32px;
  border: 0;
  display: inline-block;
  text-align: center;
  transition: all .1s ease;
}

.birthdayInput:not(:placeholder-shown){
  font-size: 48px;
  font-weight: 500;
  border-bottom: 5px solid #5ece71;
}

.birthdayInput:valid{
  border-bottom: 3px solid #eee;
}

.birthdayInput:focus{
  border-bottom: 5px solid #6BADF4;
}

.birthdayDivider{
  display: inline-block;
  font-size: 48px;
  font-weight: 100;
  color: #eee;
  vertical-align: -10%;
  margin: 12px;
}

.rewardsError{
  color: #ea5151;
  font-size: .8em;
  font-weight: 200;
}

.rewardsOptInText{
  margin: 24px 0px;
}

.couponTermsOfUse{
  font-size: .6em;
  margin: 12px 0px;
}

#couponExit{
  animation: slide-out ease 500ms forwards !important;
}

@keyframes drop-in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
    margin-top: -2.5vh;
  }
  30% {
    opacity: 0;
    transform: translateY(-20%);
    margin-top: 0vh;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    margin-top: 2.5vh;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}